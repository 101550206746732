import React, { ReactElement } from 'react';

import { ProviderSyncStatus } from '../../models/ProviderSyncStatus';
import { blue, green, red } from '@material-ui/core/colors';

import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '48px'
  }
}));

export interface ProviderStatusIconProps {
    isEditing: boolean;
    isSaveEnabled: boolean;
    providerStatus: ProviderSyncStatus;
    onSave: () => void;
}

export default function ProviderSettingsIcon(props: ProviderStatusIconProps) {
  const classes = useStyles();

  const isButtonDisabled = !(props.isEditing && props.isSaveEnabled);

  let button: ReactElement = null;
  if (props.isEditing) {
    button = (
      <IconButton 
        disabled={isButtonDisabled}
        onClick={props.onSave}
      >
        <SaveIcon />
      </IconButton>
    );
  } else {
    let icon: ReactElement;
    switch (props.providerStatus) {
      case ProviderSyncStatus.Error:
        icon = <ErrorOutlineIcon style={{ color: red[500] }}/>;
        break;
      case ProviderSyncStatus.Synced:
        icon = <CheckIcon style={{ color: green[500] }}/>;
        break;
      case ProviderSyncStatus.Syncing:
        icon = <SyncIcon style={{ color: blue[500] }}/>;
        break;
      default:
        return null;
    }

    button = (
      <div className={classes.root}>
        <IconButton disabled={isButtonDisabled}>
          {icon}
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {button}
    </div>
  );
}