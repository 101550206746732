/**
 * AccountSettings.tsx
 * 
 * This component is responsible for enabling users to configure their account settings.
 */

/* eslint-disable max-len */

import React from 'react';
import { makeStyles, Typography, Divider, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import ProviderSettings from '../../components/AccountSettings/ProviderSettings';
import { useMyTheme } from '../../hooks/theme';
import { providerConfigMap } from '../../models/ProviderConfig';
import { useProfile } from '../../hooks/profile';
import { ProviderEnum } from '../../generated/graphql-types';
import DisplayNameInput from '../../components/AccountSettings/DisplayNameInput';
import ProfilePrivacyCheckbox from '../../components/AccountSettings/ProfilePrivacyCheckbox';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'scroll',
    padding: theme.spacing(3)
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    maxWidth: '600px'
  },
  sectionDivider: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  }
}));

export default function AccountSettings() {
  const classes = useStyles();
  
  const { isDarkMode, toggleTheme } = useMyTheme();
  const { profileData, setProfileData } = useProfile();

  return (
    <div className={classes.root}>
      <Typography variant="h4">Game Library Providers</Typography>
      <Typography variant="body1">
        Link accounts to sync games to your AllMyGames library.
      </Typography>
      <div className={classes.form}>
        <ProviderSettings 
          config={providerConfigMap.get('gog')} 
          onValueChange={(gogProfileName: string) => {
            profileData.gogProfileName = gogProfileName;
            setProfileData(profileData);
          }}
          providerId={ProviderEnum.Gog}
          value={profileData.gogProfileName} />
        <ProviderSettings 
          config={providerConfigMap.get('psn')} 
          onValueChange={(psnProfileName: string) => {
            profileData.psnProfileName = psnProfileName;
            setProfileData(profileData);
          }}
          providerId={ProviderEnum.PlayStation}
          value={profileData.psnProfileName} />
        <ProviderSettings 
          config={providerConfigMap.get('steam')} 
          onValueChange={(steamCommunityId: string) => {
            profileData.steamCommunityId = steamCommunityId;
            setProfileData(profileData);
          }}
          providerId={ProviderEnum.Steam}
          value={profileData.steamProfileName} />
        <ProviderSettings 
          config={providerConfigMap.get('xbox')} 
          onValueChange={(xboxGamertag: string) => {
            profileData.xboxGamertag = xboxGamertag;
            setProfileData(profileData);
          }}
          providerId={ProviderEnum.Xbox}
          value={profileData.xboxGamertag} />
      </div>

      <Divider className={classes.sectionDivider} />

      <Typography variant="h4">Profile</Typography>
      <DisplayNameInput
        initialValue={profileData.displayName}
        onValueChange={(displayName: string) => {
          profileData.displayName = displayName;
          setProfileData(profileData);
        }}
      />

      <ProfilePrivacyCheckbox
        initialValue={profileData.isProfilePrivate} 
        onValueChange={(isProfilePrivate: boolean) => {
          profileData.isProfilePrivate = isProfilePrivate;
          setProfileData(profileData);
        }}
      />

      <Divider className={classes.sectionDivider} />

      <Typography variant="h4">Theme</Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={isDarkMode}
              onChange={toggleTheme}
              name="checkedB"
              color="primary"
            />
          }
          label="Use dark mode"
        />
      </FormGroup>
    </div>
  );
}

