/**
 * SplashScreen.tsx
 * 
 * Something nice to look at while we authenticate the user.
 */

import React from 'react';
import { createStyles, makeStyles, Theme, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: '64px',
      maxWidth: '100%',
      padding: theme.spacing(1)
    },
    message: {
      padding: theme.spacing(1)
    },
    progress: {
      margin: theme.spacing(1)
    },
    splash: {
      alignItems: 'center',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      width: '100%'
    }
  })
);

const loadingScreenMessages = [
  'Leveling up...',
  'Reticulating splines...',
  'Eroding continents...',
  'In the middle of some calibrations...',
  'Constructing additional pylons...',
  'Doing a barrel roll...',
];

function getRandomLoadingScreenMessage() {
  return loadingScreenMessages[Math.floor(Math.random() * loadingScreenMessages.length)];
}

export interface SplashScreenProps {
  message?: string;
}

export default function SplashScreen(props: SplashScreenProps) {
  const classes = useStyles();

  const loadingScreenMessage = props.message || getRandomLoadingScreenMessage();

  return (
    <div className={classes.splash}>
      <img src='./images/logo_pixel.svg' className={classes.logo} alt='' />
      <CircularProgress className={classes.progress} color="primary" />
      <Typography className={classes.message} variant="subtitle1">{loadingScreenMessage}</Typography>
    </div>
  )
}