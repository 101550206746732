import React, { useCallback, useState } from 'react';
import { Dialog, makeStyles, Button, DialogTitle, Typography, CircularProgress, Tooltip } from '@material-ui/core';
import { useDeferredQuery } from '../hooks/query-hook';
import {
  Search_Game_QueryQueryVariables,
  Search_Game_QueryQuery,
  Add_Custom_Games_MutationMutation,
  Add_Custom_Games_MutationMutationVariables
} from '../generated/graphql-types';
import { SEARCH_GAME_QUERY } from '../graphql/queries';
import { ApolloQueryResult } from 'apollo-boost';
import SearchBar from './SearchBar';
import FullBleedImage from './FullBleedImage';
import { useMutation } from '@apollo/react-hooks';
import { ADD_CUSTOM_GAMES_MUTATION } from '../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  errorStringsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
  },
  gameCardAndNameContainer: {
    width: '160px',
    margin: `${theme.spacing(1)}px`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  gameCardContainer: {
    borderRadius: '3px',
    overflow: 'hidden',
  },
  gameTitle: {
    lineHeight: '1.5',
  },
  gameTitleContainer: {
    height: '2.5rem',
    overflow: 'hidden',
  },
  searchBarAndButtonContainer: {
    display: 'flex',
    margin: '1rem'
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: '0.5rem',
  },
  resultsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export interface AddCustomGameModalProps {
  onClose: (refreshCollection?: boolean) => void;
  open: boolean;
  isWishlist: boolean;
}

interface GamesFromSearch {
  igdbId: number;
  image?: string;
  name: string;
  addedToLibrary: boolean;
  addingToLibrary: boolean;
}

export default function AddCustomGameModal(props: AddCustomGameModalProps) {
  const classes = useStyles();
  
  const { open, onClose, isWishlist } = props;
  
  const [atLeastOneGameAdded, updateAtLeastOneGameAdded] = useState(false);
  const [foundGames, updateFoundGames] = useState<GamesFromSearch[]>([]);
  const [noGamesFound, updateNoGamesFound] = useState(false);
  const [searchTerm, updateSearchTerm] = useState('');

  const handleCloseDialog = useCallback(() => {
    updateFoundGames([]);
    updateSearchTerm('');
    updateAtLeastOneGameAdded(false);

    onClose(atLeastOneGameAdded);
  }, [atLeastOneGameAdded, onClose]);

  const findCustomGamesCallback = useCallback((result: ApolloQueryResult<Search_Game_QueryQuery>) => {
    if (result.data.searchGame?.games && result.data.searchGame.games.length > 0) {
      const decoratedResults = result.data.searchGame.games.map(game => {
        return { ...game, addedToLibrary: false, addingToLibrary: false };
      });
      updateFoundGames(decoratedResults);
      updateNoGamesFound(false);
    }
    else {
      updateFoundGames([]);
      updateNoGamesFound(true);
    }
  }, [updateFoundGames, updateNoGamesFound]);

  const addCustomGamesCallback = useCallback((result: Add_Custom_Games_MutationMutation) => {
    if (result.addCustomGames.succeeded) {
      const foundGamesCopy = [...foundGames];
      result.addCustomGames.gameIds.forEach(gameId => {
        foundGamesCopy.find(game => game.igdbId === gameId).addingToLibrary = false;
        foundGamesCopy.find(game => game.igdbId === gameId).addedToLibrary = true;
      })
      updateFoundGames(foundGamesCopy);
    }
  }, [foundGames, updateFoundGames]);

  const { execute: executeSearch, loading, error: findCustomGamesError } =
    useDeferredQuery<Search_Game_QueryQuery, Search_Game_QueryQueryVariables>(SEARCH_GAME_QUERY, findCustomGamesCallback);

  const [addCustomGames] =
    useMutation<Add_Custom_Games_MutationMutation, Add_Custom_Games_MutationMutationVariables>(ADD_CUSTOM_GAMES_MUTATION,
      { onCompleted: addCustomGamesCallback });

  const handleAddGame = useCallback((gameId: number) => {
    const foundGamesCopy = [...foundGames];
    const gameBeingAdded = foundGamesCopy.find(game => game.igdbId === gameId);

    // TODO: if this is not true, something has gone wrong. We should add logging
    if (gameBeingAdded) {
      gameBeingAdded.addingToLibrary = true;
    }

    updateFoundGames(foundGamesCopy);
    updateAtLeastOneGameAdded(true);

    addCustomGames({
      variables: {
        input: {
          gameIds: [gameId],
          isWishlist
        },
      }
    });
  }, [addCustomGames, foundGames, isWishlist]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>Find game to add to collection</DialogTitle>

      <div className={classes.searchBarAndButtonContainer}>
        <div className={classes.searchBarContainer}>
          <SearchBar
            setSearchQuery={(updatedSearchTerm) => updateSearchTerm(updatedSearchTerm)}
            fixedSize={true}
            enterPressedHandler={() => executeSearch({ input: { searchTerm } })}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={searchTerm === ''}
          onClick={() => executeSearch({ input: { searchTerm } })}>
          {loading ? <CircularProgress size={'1rem'} color="secondary" /> : 'Find Games'}
        </Button>
      </div>

      <div className={classes.errorStringsContainer}>
        {findCustomGamesError &&
          <Typography>
            Error searching for games - please try again later.
          </Typography>
        }
        {noGamesFound &&
          <Typography>
            No games found for this search term. Please try searching using different words!
          </Typography>
        }
      </div>

      <div className={classes.resultsContainer}>
        {
          foundGames.map(game =>
            (
              <div className={classes.gameCardAndNameContainer}>
                <Tooltip title={game.name} aria-label={game.name} placement='top'>
                  <div>
                    <div className={classes.gameCardContainer}>
                      <FullBleedImage
                        height={200}
                        minWidth={160}
                        url={game.image}
                      />
                    </div>
                    <div className={classes.gameTitleContainer}>
                      <Typography classes={{ overline: classes.gameTitle }} variant="overline">{game.name}</Typography>
                    </div>
                  </div>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={game.addedToLibrary}
                  onClick={() => handleAddGame(game.igdbId)}>
                  {game.addingToLibrary ? <CircularProgress size={'1.5rem'} color="secondary" /> :
                    game.addedToLibrary ? 'Added!' : 'Add game'}
                </Button>
              </div>
            ) 
          )
        }
      </div>
    </Dialog>
  );
}