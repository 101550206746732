import React from 'react';
import { IconButton, Theme, makeStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyles = makeStyles((theme: Theme) => ({
  favoriteIconButton: {
    padding: 0
  }    
}));

export interface FavoriteControlProps {
    toggleFavorite: (event: React.MouseEvent) => void
    isFavorite: boolean;
}

export function FavoriteControl(props: FavoriteControlProps) {
  const { isFavorite, toggleFavorite } = props;
  const classes = useStyles();

  return (              
    <IconButton
      className={classes.favoriteIconButton} onClick={toggleFavorite}>
      {isFavorite 
        ? <FavoriteIcon /> 
        : <FavoriteBorderIcon />}
    </IconButton>);
}