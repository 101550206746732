import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, Card, Link, CardContent } from '@material-ui/core';
import AllMyGamesAppBar from '../../components/AllMyGamesAppBar';
import useQuery from '../../hooks/query-hook';
import { Social_Feed_QueryQuery, Social_Feed_QueryQueryVariables, SocialFeedItemType } from '../../generated/graphql-types';
import { SOCIAL_FEED_QUERY } from '../../graphql/queries';
import { ApolloQueryResult } from 'apollo-boost';
import LoadingPane from '../../components/LoadingPane';
import FlexGrowBuffer from '../../components/FlexGrowBuffer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
      overflowY: 'scroll',
    },
    appBar: {
      backgroundColor: theme.palette.primary.main
    },
    cardsContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '720px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    socialFeedItemCard: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3)
    },
    socialFeedUserProfileLink: {
      fontWeight: 'bold'
    },
    title: {
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    }
  })
);

interface SocialFeedPageState {
  loading: boolean;
  items: SocialFeedItemType[];
}

export interface SocialFeedPageProps {
  toggleAppDrawer: () => void;
}

export default function SocialFeedPage(props: SocialFeedPageProps) {
  const classes = useStyles();
  const [state, setState] = useState<SocialFeedPageState>({
    loading: true,
    items: null
  });

  useQuery<Social_Feed_QueryQuery, Social_Feed_QueryQueryVariables>(
    SOCIAL_FEED_QUERY,
    (result: ApolloQueryResult<Social_Feed_QueryQuery>) => {
      if (result.data.feed == null) {
        // Response contains no data
        setState({ ...state, loading: false });
        return;
      }

      setState({
        items: result.data.feed.items,
        loading: false
      });
    }
  );

  if (state.loading) {
    return <LoadingPane />;
  }
  
  return (
    <div className={classes.root}>
      <AllMyGamesAppBar 
        config={{
          heading: 'Social Feed'
        }}
        toggleAppDrawer={props.toggleAppDrawer} />
      <div className={classes.content}>
        <div className={classes.cardsContainer}>
          <>
            {state.items.map(x => 
              <Card className={classes.socialFeedItemCard}>
                <div style={{display: 'flex'}}>
                  <Link 
                    component="a" 
                    href={`/user/${x.displayName}`} 
                    color="textPrimary"
                    className={classes.socialFeedUserProfileLink}>
                    {x.displayName}
                  </Link>

                  <FlexGrowBuffer />

                  <div>{x.eventAgeString}</div>
                </div>
                <div style={{marginTop: '10px'}}>
                  {x.text}
                </div>
              </Card>
            )}
            {state.items.length === 0 &&
              <Card>
                <CardContent>
                  No social updates found. Try following other users to see what they've been playing!
                </CardContent>
              </Card>
            }
          </>
        </div>
      </div>
    </div>
  );
}