import React, {  } from 'react';
import { 
  GameMappingType 
} from '../../../generated/graphql-types';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import IgdbGameMappingTableRow from './IgdbGameMappingTableRow';
import { CircularProgress } from '@material-ui/core';

interface IgdbGameMappingTableProps {
  hasNextPage: boolean,
  isNextPageLoading: boolean,
  itemCount: number,
  items: GameMappingType[],
  loadNextPage: () => void,
}

export default function IgdbGameMappingTable({ 
  hasNextPage,
  isNextPageLoading,
  itemCount,
  items,
  loadNextPage,
}: IgdbGameMappingTableProps) {
  if (!items || items.length === 0) {
    return null;
  }

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;

  // Render an item or a loading indicator.
  const Item = ({ index, style, data }) => {
    if (isItemLoaded(index)) {
      return <IgdbGameMappingTableRow
        key={index}
        index={index} 
        style={style} 
        data={data} />;
    } else {
      return (
        <div 
          key={index}
          style={{
            ...style
          }}>
          <CircularProgress />
        </div>
      );
    }
  };

  return (
    <AutoSizer>
      {({ height, width }) =>
        <InfiniteLoader 
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? itemCount + 1 : itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={height}
              itemData={items}
              itemCount={itemCount}
              itemSize={60}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width}
            >
              {Item}
            </FixedSizeList>    
          )}
        </InfiniteLoader>
      }
    </AutoSizer>
  );
}