import { 
  Toolbar, IconButton, makeStyles, Theme, createStyles, Typography
} from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.main,
      flexGrow: 1, 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export interface AllMyGamesAppBarConfig {
  heading: string;
}

export interface AllMyGamesAppBarProps {
  config: AllMyGamesAppBarConfig;
  toggleAppDrawer: () => void;
}

export default function AllMyGamesAppBar(props: AllMyGamesAppBarProps) {
  const classes = useStyles();
  return (
    <Toolbar className={classes.appBar}>
      <IconButton edge="start" className={classes.menuButton}
        color="inherit" aria-label="menu"
        onClick={props.toggleAppDrawer}>
        <MenuIcon />
      </IconButton>
      <Typography variant="h6">
        {props.config.heading}  
      </Typography>
    </Toolbar>
  );
}