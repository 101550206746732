import React from 'react';
import { makeStyles, createStyles, Typography, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold'
    },
    italic: {
      fontStyle: 'italic'
    }
  }));

export default function PrivacyPage() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Typography variant="h2">
        Privacy Policy of Condorcet, LLC
      </Typography>

      <p>Condorcet, LLC operates the allmy.games website, which provides the SERVICE.</p>

      <p>This page is used to inform website visitors about our policies regarding the collection, use, and disclosure
        of Personal Information for anyone deciding to use our Service, the AllMyGames website.</p>

      <p>If you choose to use our Service, then you agree to the collection and use of information in relation with
      this policy. The Personal Information that we collect are used for providing and improving the Service. We will
         not use or share your information with anyone except as described in this Privacy Policy.</p>

      <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
        accessible at allmygames.com, unless otherwise defined in this Privacy Policy.</p>

      <Typography variant="h4">
        Information Collection and Use
      </Typography>

      <p>We only collect information about you if we have a reason to do so–for example, to provide our Services, to
        communicate with you, or to make our Service better.</p>

      <p>We collect information in two ways: if and when you provide information to us, and automatically through
        operating our Service. Let’s go over the information that we collect.</p>

      <Typography variant="h5" className={classes.italic}>
        Information You Provide to Us
      </Typography>

      <p>It’s probably no surprise that we collect information that you provide to us. The amount and type of
        information depends on the context and how we use the information. Here are some examples:</p>

      <ul>
        <li>
          <span className={classes.bold}>Basic Account Information: </span>
        We ask for basic information from you in order to set up your account. For example, we require individuals who
        sign up for an AllMyGames account to provide an email address.
        </li>
        <li>
          <span className={classes.bold}>Content Information: </span>
          Depending on how you use the site, you will likely also provide us with information about your game library.
          For example, if you add games to your account, or automatically import games from your Steam, Xbox,
          Playstation, or GOG accounts, we will store the entire list of games, as well as your account identifiers.
          This might be obvious to you… but it’s not to everyone!
        </li>
      </ul>

      <Typography variant="h5" className={classes.italic}>Information We Collect Automatically</Typography>

      <p>We also collect some information automatically:</p>

      <ul>
        <li>
          <span className={classes.bold}>Log Information:  </span>
          Like most online service providers, we collect information that web browsers, mobile devices, and servers
          typically make available, such as the browser type, IP address, unique device identifiers, language
          preference, referring site, the date and time of access, operating system, and mobile network information.
          We collect log information when you use our Service–for example, when you add new games to your collection.
        </li>
        <li>
          <span className={classes.bold}>Usage Information:  </span>
          We collect information about your usage of our Service. For example, we collect information about the actions
          that users take on our site–in other words, who did what, when and to what thing on a site. We also collect
          information about what happens when you use our Service (e.g., page views, usage of certain features) along
          with information about your device (e.g., screen size, name of cellular network, and mobile device
          manufacturer). We use this information to, for example, provide our Service to you, as well as get insights
          on how people use our Service, so we can make our Service better.
        </li>
        <li>
          <span className={classes.bold}>Information from Cookies &amp; Other Technologies:   </span>
          A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s
          browser provides to the website each time the visitor returns. Pixel tags (also called web beacons) are
          small blocks of code placed on websites and emails. Our website uses these "cookies" to collection information
          and to improve our Service. You have the option to either accept or refuse these cookies, and know when a
          cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use
          some portions of our Service.
        </li>
      </ul>

      <Typography variant="h4">Service Providers</Typography>

      <p>We may employ third-party companies and individuals due to the following reasons:</p>

      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>

      <p>We want to inform our Service users that these third parties have access to your Personal Information.
      The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.</p>

      <ul>
        <li>
          <span className={classes.bold}>Google Analytics:  </span>
          Like many other sites on the internet, we use a tool called “Google Analytics” to collect information about
          use of this Service. Google Analytics collects information such as how often users visit this Service, what
          pages they visit when they do so, and what other sites they used prior to coming to this Service. We use the
          information we get from Google Analytics only to improve this Service. Google Analytics collects only the IP
          address assigned to you on the date you visit this site and a unique identifier that we supply, rather than
          your name or other identifying information. We do not combine the information collected through the use of
          Google Analytics with personally identifiable information. Although Google Analytics plants a permanent
          cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie
          cannot be used by anyone but Google. Google’s ability to use and share information collected by Google
          Analytics about your visits to this site is restricted by the Google Analytics Terms of Service and the
          Google Privacy Policy. You can prevent Google Analytics from recognizing you on return visits to this site
          by disabling cookies on your browser or using a browser plugin to opt out of all Google Analytics tracking
          software.
        </li>
        <li>
          <span className={classes.bold}>MailChimp:  </span>
          If you choose to sign up for an account with our Service, the email address that you submit to us will be
          forwarded to Mailchimp, who provide us with email services. Your e-mail will remain within this database for
          as long as we continue to use MailChimp's services for email marketing or until you delete your AllMyGames
          account.
        </li>
      </ul>

      <Typography variant="h4">Security</Typography>

      <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially
      acceptable means of protecting it. But remember that no method of transmission over the internet, or method
        of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <Typography variant="h4">Links to Other Sites</Typography>

      <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that
      site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the
      Privacy Policy of these websites. We have no control over, and assume no responsibility for the content,
        privacy policies, or practices of any third-party sites or services.</p>

      <Typography variant="h4">Children’s Privacy</Typography>

      <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable
      information from children under 13. In the case we discover that a child under 13 has provided us with personal
      information, we immediately delete this from our servers. If you are a parent or guardian and you are aware
      that your child has provided us with personal information, please contact us so that we will be able to do
        necessary actions.</p>

      <Typography variant="h4">Changes to This Privacy Policy</Typography>

      <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for
      any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes
        are effective immediately, after they are posted on this page.</p>

      <Typography variant="h4">Contact Us</Typography>

      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
    </Container>
  );
}