import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import history from './utils/history';
import getEnvVars from './utils/environment';
import ReactGA from 'react-ga';

import App from './App';

const { googleAnalyticsTrackingId } = getEnvVars();

// Initialize Google Analytics
if (googleAnalyticsTrackingId) {
  ReactGA.initialize(googleAnalyticsTrackingId);
  ReactGA.pageview(window.location.pathname);

  history.listen((location) => {
    ReactGA.pageview(location.pathname);
  });
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

serviceWorker.unregister();
