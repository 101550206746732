import React, { } from 'react';

import { Router, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

import history from '../../utils/history';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Followers from './Followers';
import Following from './Following';
import Profile from './Profile';
import AllMyGamesAppBar from '../../components/AllMyGamesAppBar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    appBar: {
      backgroundColor: theme.palette.primary.main
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
      overflowY: 'scroll'
    },
  })
);

export interface UserProfilePageProps {
  displayName: string;
  toggleAppDrawer: () => void;
}

export default function UserProfilePage(props: UserProfilePageProps) {
  const classes = useStyles();

  return (
    <Router history={history}>
      <div className={classes.root}>
        <AllMyGamesAppBar
          config={{
            heading: 'User Profile'
          }}
          toggleAppDrawer={props.toggleAppDrawer} />
        <div className={classes.content}>
          <Switch>
            <Route
              path={`/user/${props.displayName}/followers`} exact
              render={() => <Followers {...props} />}
            />
            <Route
              path={`/user/${props.displayName}/following`} exact
              render={() => <Following {...props} />}
            />
            <Route
              path={`/user/${props.displayName}`}
              render={() => <Profile {...props} />}
            />
          </Switch>
        </div>
      </div>
    </Router>
  );
}