import { gql } from 'apollo-boost';
import { DEVELOPER_GAME_MAPPING_FRAGMENT } from './fragments';

export const ADD_CUSTOM_GAMES_MUTATION = gql`
  mutation ADD_CUSTOM_GAMES_MUTATION($input: AddCustomGamesInputType!){
    addCustomGames(input: $input){
      succeeded
      gameIds
    }
  }
`;

export const DEVELOPER_COMPUTE_MAPPING_SCORES = gql`
  mutation DEVELOPER_COMPUTE_MAPPING_SCORES {
    developer {
      computeMappingScores {
        ...GameMappingFragment
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const DEVELOPER_REFETCH_GAME_MAPPING = gql`
  mutation DEVELOPER_REFETCH_GAME_MAPPING($input: RefetchGameMappingInputType!) {
    developer {
      refetchGameMapping(input: $input) {
        ...GameMappingFragment
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const DEVELOPER_UPDATE_GAME_MAPPING = gql`
  mutation DEVELOPER_UPDATE_GAME_MAPPING($input: UpdateGameMappingInputType!) {
    developer {
      updateGameMapping(input: $input) {
        ...GameMappingFragment
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const DEVELOPER_VERIFY_GAME_MAPPING = gql`
  mutation DEVELOPER_VERIFY_GAME_MAPPING($input: VerifyGameMappingInputType!) {
    developer {
      verifyGameMapping(input: $input) {
        ...GameMappingFragment
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const DEVELOPER_UPDATE_GAME_MAPPINGS = gql`
  mutation DEVELOPER_UPDATE_GAME_MAPPINGS($input: UpdateGameMappingsInputType!) {
    developer {
      updateGameMappings(input: $input) {
        ...GameMappingFragment
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const REMOVE_CUSTOM_GAME_MUTATION = gql`
  mutation REMOVE_CUSTOM_GAME_MUTATION($input: RemoveCustomGameInputType!) {
    removeCustomGame(input: $input) {
      succeeded
    }
  }
`;

export const SYNC_PROVIDERS_MUTATION = gql`
  mutation SYNC_PROVIDERS_MUTATION {
    syncProviders {
      succeeded
    }
  }
`;

export const UPDATE_BACKLOG_POSITION_MUTATION = gql`
  mutation UPDATE_BACKLOG_POSITION_MUTATION($backlogPosition: Int!, $gameId: String!) {
    updateBacklogEntry(backlogPosition: $backlogPosition, gameId: $gameId) {
      backlogPosition
      game {
        id
      }
    }
  }
`;

export const UPDATE_DISPLAY_NAME_MUTATION = gql`
  mutation UPDATE_DISPLAY_NAME_MUTATION($input: UpdateDisplayNameInputType!) {
    updateDisplayName(input: $input) {
      displayName
      updateSucceeded
    }
  }
`;

export const UPDATE_FOLLOWING_MUTATION = gql`
  mutation UPDATE_FOLLOWING_MUTATION($input: UpdateFollowingInputType!) {
    updateFollowing(input: $input) {
      isVisitorFollowing
    }
  }
`;

export const UPDATE_LIBRARY_ENTRY_MUTATION = gql`
  mutation UPDATE_LIBRARY_ENTRY_MUTATION($gameId: String!, $isFavorite: Boolean, $isHidden: Boolean, $rating: Int, $state: LibraryGameState) {
    updateLibraryEntry(gameId: $gameId, isFavorite: $isFavorite, isHidden: $isHidden, rating: $rating, state: $state) {
      game {
        id
      }
      isFavorite
      isHidden
      rating
      state
    }
  }
`;

export const UPDATE_PROFILE_PRIVACY_MUTATION = gql`
  mutation UPDATE_PROFILE_PRIVACY_MUTATION($input: UpdateProfilePrivacyInputType!) {
    updateProfilePrivacy(input: $input) {
      isProfilePrivate
    }
  }
`;

export const UPDATE_PROVIDER_PROFILE_NAME_MUTATION = gql`
  mutation UPDATE_PROVIDER_PROFILE_NAME_MUTATION($input: UpdateProviderProfileNameInputType!) {
    updateProviderProfileName(input: $input) {
      profileName
      providerState
    }
  }
`;