/**
 * environment.ts
 * 
 * Defines environment variables for each environment stage.
 * This enables us to configure values specific to running the app in development, staging, or production environments.
 */

const ENV = {
  dev: {
    apiUrl: 'http://localhost:5000',
    googleAnalyticsTrackingId: 'UA-170233483-2',
    redirectUrl: 'http://localhost:3000',
  },
  prod: {
    apiUrl: 'https://api.allmy.games',
    googleAnalyticsTrackingId: 'UA-170233483-1',
    redirectUrl: 'https://allmy.games',
  }
};

const getEnvVars = (env = process.env.REACT_APP_ENVIRONMENT) => {
  switch (env) {
    case 'dev':
      return ENV.dev;
    case 'prod':
      return ENV.prod;
    default:
      // Default to dev
      return ENV.dev;
  }
};

export default getEnvVars;
