/**
 * graphql.tsx
 * 
 * This file defines a set of custom React hooks that enable us to communicate with a GraphQL server instance.
 */

import React, { useState, useContext, useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth } from './auth';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import getEnvVars from '../utils/environment';

const { apiUrl } = getEnvVars();

function buildApolloClient(token: string): ApolloClient<InMemoryCache> {
  return new ApolloClient<InMemoryCache>({
    cache: new InMemoryCache(),
    uri: `${apiUrl}/graphql`,
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  });
}

export const GraphQLContext = React.createContext(undefined);
export const useGraphQL = () => useContext(GraphQLContext);
export const GraphQLProvider = ({
  children,
  ...initOptions
}) => {
  const { bearerToken } = useAuth();
  const [apolloClient, setApolloClient] = useState<ApolloClient<InMemoryCache>>(buildApolloClient(bearerToken));

  useEffect(() => {
    // If the user's authentication state changes, update the ApolloClient instance
    setApolloClient(buildApolloClient(bearerToken));
  }, [bearerToken])

  return (
    <GraphQLContext.Provider value={{}}>
      <ApolloProvider client={apolloClient}>
        {children}
      </ApolloProvider>
    </GraphQLContext.Provider>
  );
};