import React, { useState } from 'react';
import { makeStyles, Typography, Card, IconButton } from '@material-ui/core';
import { UserFollowProps } from '../../components/UserProfile/UserFollow';
import UserFollowList from '../../components/UserProfile/UserFollowList';
import { USER_FOLLOWERS_QUERY } from '../../graphql/queries';
import {
  User_Followers_QueryQuery, User_Followers_QueryQueryVariables
} from '../../generated/graphql-types'
import useQuery from '../../hooks/query-hook';
import { ApolloQueryResult } from 'apollo-boost';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router-dom';
import { useProfile } from '../../hooks/profile';
import LoadingPane from '../../components/LoadingPane';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'scroll',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '720px'
  },
  heading: {
    display: 'flex',
    flexDirection: 'row'
  },
  profileCard: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  profileCardContent: {
    display: 'flex'
  }
}));

interface FollowersProps {
  displayName: string;
}

interface FollowersState {
  loading: boolean;
  followers: UserFollowProps[];
}

export default function Followers(props: FollowersProps) {
  const classes = useStyles();

  const history = useHistory();
  const { profileData: visitorProfileData } = useProfile();

  const [state, setState] = useState<FollowersState>({
    loading: true,
    followers: null
  });

  useQuery<User_Followers_QueryQuery, User_Followers_QueryQueryVariables>(
    USER_FOLLOWERS_QUERY, (result: ApolloQueryResult<User_Followers_QueryQuery>) => {
      if (result.data.user == null) {
        // Response contains no user or followers
        setState({ ...state, loading: false });
        return;
      }

      let followersResponse = result.data.user.followers;
      let followersProps = followersResponse.map(f => {
        return {
          displayName: f.displayName,
          isVisitorFollowing: f.isVisitorFollowing,
          visitorDisplayName: visitorProfileData.displayName
        } as UserFollowProps;
      });
      setState({
        loading: false,
        followers: followersProps
      });
    },
    { input: { displayName: props.displayName } });

  if (state.loading) {
    return <LoadingPane />;
  }

  if (state.followers == null) {
    return <Redirect to="/" />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card className={classes.profileCard}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h5">
            {props.displayName}
          </Typography>
        </Card>
        <Typography variant="subtitle1">Followers</Typography>
        {state.followers.length > 0 ?
          <UserFollowList userFollows={state.followers} /> :
          <Typography variant="body1">{`${props.displayName} has no followers`}</Typography>
        }
      </div>
    </div>
  );
}