/**
 * Library.tsx
 * 
 * This component is responsible for displaying the Game Library page.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { GameCardProps } from '../../components/GameCard';
import LibraryDrawer, { GameStateFilterValue } from '../../components/Home/LibraryDrawer';
import AutoSizer from 'react-virtualized-auto-sizer';
import GameGrid from '../../components/GameGrid';
import { LIBRARY_QUERY } from '../../graphql/queries';
import { UPDATE_LIBRARY_ENTRY_MUTATION, SYNC_PROVIDERS_MUTATION } from '../../graphql/mutations';
import useQuery from '../../hooks/query-hook';
import SelectedGameModal from '../../components/SelectedGameModal';
import { useMutation } from '@apollo/react-hooks';
import {
  Library_QueryQuery, Library_QueryQueryVariables, Sync_Providers_MutationMutationVariables, Sync_Providers_MutationMutation
} from '../../generated/graphql-types';
import { ApolloQueryResult } from 'apollo-boost';
import AddCustomGameModal from '../../components/AddCustomGamesModal';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  games: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  gamesHeader: {
    color: 'rgba(255,255,255,.5)',
    textAlign: 'center'
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50px'
  },
  root: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  }
}));

export interface LibraryProps {
  drawerOpen: boolean,
  onDrawerClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void,
  searchQuery: string
}

export default function Library(props: LibraryProps) {
  const classes = useStyles();

  const [favoritesFilterEnabled, setFavoritesFilterEnabled] = useState<boolean>(false);
  const [games, setGames] = useState<GameCardProps[]>(null);
  const [gameStateFilter, setGameStateFilter] = useState<GameStateFilterValue>('ALL');
  const [hiddenFilterEnabled, setHiddenFilterEnabled] = useState<boolean>(false);
  const [openCustomGameModal, setOpenCustomGameModal] = useState<boolean>(false);
  const [openSelectedGameModal, setOpenSelectedGameModal] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<GameCardProps>(null);
  const [sortedGames, setSortedGames] = useState<GameCardProps[]>(null);
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [sortType, setSortType] = useState<string>('name');
  const [syncing, setSyncing] = useState<boolean>(false);

  const [syncProviders] = useMutation<Sync_Providers_MutationMutation, Sync_Providers_MutationMutationVariables>(SYNC_PROVIDERS_MUTATION);
  const [updateLibraryEntry] = useMutation(UPDATE_LIBRARY_ENTRY_MUTATION);

  const handleCloseSelectedGameModal = useCallback(() => {
    setOpenSelectedGameModal(false);
    setSelectedGame(null);
  }, [setSelectedGame, setOpenSelectedGameModal]);
  
  const handleOpen = useCallback((game: GameCardProps) => {
    setSelectedGame(game);
    setOpenSelectedGameModal(true);
  }, [setSelectedGame, setOpenSelectedGameModal]);

  const handleToggleFavorite = useCallback((game: GameCardProps) => {
    updateLibraryEntry({
      variables: {
        gameId: game.id,
        isFavorite: !game.isFavorite
      }
    });
    game.isFavorite = !game.isFavorite;
  }, [updateLibraryEntry]);

  const handleUpdateRating = useCallback((game: GameCardProps, newRating: number) => {
    updateLibraryEntry({
      variables: {
        gameId: game.id,
        rating: newRating
      }
    });
    game.rating = newRating;
  }, [updateLibraryEntry]);

  const queryCallback = useCallback((result: ApolloQueryResult<Library_QueryQuery>) => {
    if (result.data.me) {
      setGames(result.data.me.library.map(entry => {
        return {
          key: entry.game.id,
          id: entry.game.id,
          title: entry.game.title,
          image: entry.game.image,
          favoriteToggleEnabled: true,
          isFavorite: entry.isFavorite,
          isHidden: entry.isHidden,
          isWishlist: false,
          provider: entry.provider,
          rating: entry.rating,
          state: entry.state,
          get onClick() {
            return () => handleOpen(this);
          },
          get toggleFavorite() {
            return (event: React.MouseEvent) => {
              handleToggleFavorite(this);
              event.stopPropagation();
            }
          },
          get updateRating() {
            return (event: React.MouseEvent, value: number) => {
              handleUpdateRating(this, value);
              event.stopPropagation();
            }
          },
          ratingControlEnabled: true
        } as GameCardProps;
      }));
    }
  }, [handleOpen, handleToggleFavorite, handleUpdateRating]);

  const { reload, loading } = useQuery<Library_QueryQuery, Library_QueryQueryVariables>(LIBRARY_QUERY, queryCallback);

  const handleCloseCustomGameModal = useCallback((refreshLibrary?: boolean) => {
    setOpenCustomGameModal(false);
    if (refreshLibrary) {
      reload();
    }
  }, [setOpenCustomGameModal, reload]);

  const handleRefresh = useCallback(async () => {
    setSyncing(true);
    let result = await syncProviders();
    if (result.data.syncProviders?.succeeded) {
      reload();
    }
    setSyncing(false);
  }, [reload, syncProviders]);

  const sortAndFilter = useCallback(() => {
    if (!games) {
      return;
    }

    let sorted: GameCardProps[] = [...games];
    if (favoritesFilterEnabled) {
      sorted = sorted.filter(x => x.isFavorite);
    }

    if (!hiddenFilterEnabled) {
      sorted = sorted.filter(x => !x.isHidden);
    }

    if (gameStateFilter !== 'ALL') {
      sorted = sorted.filter(x => x.state === gameStateFilter);
    }

    switch (sortType) {
      case 'name':
      default:
        sorted.sort((x, y) => x.title.localeCompare(y.title));
        break;
      case 'rating':
        sorted.sort((x, y) => x.rating - y.rating);
    }

    if (sortOrder === 'desc') {
      sorted.reverse()
    }

    const lowerCaseSearchQuery = props.searchQuery.toLowerCase();
    sorted = sorted.filter(x => x.title.toLowerCase().includes(lowerCaseSearchQuery));

    setSortedGames(sorted);
  }, [favoritesFilterEnabled, gameStateFilter, games, hiddenFilterEnabled, props.searchQuery, sortOrder, sortType]);

  /**
   * Sort the entries in GameGrid whenever sortOrder or sortType change
   */
  useEffect(() => {
    sortAndFilter();
  }, [games, favoritesFilterEnabled, hiddenFilterEnabled, gameStateFilter, sortOrder, sortType, props.searchQuery, sortAndFilter]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading &&
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          }

          {!loading && sortedGames && sortedGames.length !== 0 &&
            <AutoSizer>
              {({ height, width }) =>
                <GameGrid games={sortedGames} height={height} width={width} />
              }
            </AutoSizer>
          }
        </div>
        <LibraryDrawer
          className={classes.drawer}
          loading={loading || syncing}
          onPressRefresh={() => handleRefresh()}
          onPressAddCustom={() => setOpenCustomGameModal(true)}
          open={props.drawerOpen}
          onClose={props.onDrawerClose}
          sortOrder={sortOrder}
          sortType={sortType}
          gameStateFilter={gameStateFilter}
          handleSortOrderChange={setSortOrder}
          handleSortTypeChange={setSortType}
          handleGameStateFilterChange={setGameStateFilter}
          favoritesFilterEnabled={favoritesFilterEnabled}
          handleFavoritesFilterEnabledChange={setFavoritesFilterEnabled}
          hiddenFilterEnabled={hiddenFilterEnabled}
          handleHiddenFilterEnabledChange={setHiddenFilterEnabled}
          showFiltersAndSorts={true}
          showAddCustom={true}
        />
      </div>

      <SelectedGameModal
        onClose={handleCloseSelectedGameModal}
        open={openSelectedGameModal}
        onDelete={() => {
          setOpenSelectedGameModal(false);
          reload();
        }}
        selectedGame={selectedGame}
        onGameHiddenChange={(isHidden: boolean) => {
          if (!hiddenFilterEnabled) {
            sortAndFilter();
          }
        }}
      />

      <AddCustomGameModal
        open={openCustomGameModal}
        onClose={handleCloseCustomGameModal}
        isWishlist={false}
      />
    </>
  );
};