/**
 * query-hook.tsx
 * 
 * This files defines a custom React hook that issues a GraphQL query.
 */

import { DocumentNode } from 'graphql'
import { useApolloClient } from '@apollo/react-hooks'
import { useState, useCallback, useEffect } from 'react';
import { useAuth } from './auth';
import { ApolloQueryResult } from 'apollo-boost';

export default function useQuery<TResponse, TVariables>(
  gqlQuery: DocumentNode,
  onCompleted: (result: ApolloQueryResult<TResponse>) => void,
  variables?: TVariables) {
  const { isAuthenticated } = useAuth();

  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const executeQuery = useCallback((): void => {
    setLoading(true);

    apolloClient.query<TResponse, TVariables>({
      query: gqlQuery,
      // TODO: Use cache on initial queries
      fetchPolicy: 'network-only',
      variables: variables
    }).then(
      (result: ApolloQueryResult<TResponse>) => {
        onCompleted(result);
        setLoading(false);
      }
    )
  }, [apolloClient, gqlQuery, onCompleted, variables]);

  useEffect(() => {
    if (isAuthenticated && !loaded) {
      executeQuery();
      setLoaded(true);
    }
  }, [loaded, executeQuery, isAuthenticated]);

  return {
    reload: executeQuery,
    loading
  };
}

export function useDeferredQuery<TResponse, TVariables>(
  gqlQuery: DocumentNode,
  onCompleted: (result: ApolloQueryResult<TResponse>) => void) {
  const { isAuthenticated } = useAuth();

  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const executeQuery = useCallback(async (variables?: TVariables): Promise<void> => {
    if (isAuthenticated) {
      setLoading(true);
      setError(false);

      try {
        const result = await apolloClient.query<TResponse, TVariables>({
          query: gqlQuery,
          // TODO: Use cache on initial queries
          fetchPolicy: 'network-only',
          variables: variables
        });

        onCompleted(result);

      }
      catch{
        setError(true);
      }
      finally {
        setLoading(false);
      }
    }
    else {
      // TODO: We should add logging here.
      setError(true);
    }
  }, [apolloClient, gqlQuery, onCompleted, isAuthenticated]);

  return {
    execute: executeQuery,
    loading,
    error
  };
}