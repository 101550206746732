import React from 'react';
import './index.css';
import { Route, Router, Switch } from 'react-router-dom';

import history from './utils/history';
import { AuthProvider } from './hooks/auth';
import { MyThemeProvider } from './hooks/theme';
import LandingPage from './views/Landing/LandingPage';
import PrivacyPage from './views/PrivacyPage';

import Amplify, { Auth } from 'aws-amplify';
import AllMyGamesRouter from './routers/AllMyGamesRouter';

const awsConfig = {
  Auth: {
    // Amazon Cognito Region
    region: 'us-east-1',

    // Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_YkLtNqtoz',

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6l0jii96ej1e13477gdrirmdj9',

    // Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
}

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

export default function App() {
  return (
    <MyThemeProvider>
      <AuthProvider>
        <Router history={history}>
          <Switch>
            <Route
              path="/(library|backlog|wishlist|settings|user|social|dev)/"
              render={() => <AllMyGamesRouter />} />
            <Route
              path="/privacy"
              component={PrivacyPage} />
            <Route
              path="/"
              component={LandingPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </MyThemeProvider>
  );
};