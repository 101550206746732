import React from 'react';

import { Router, Switch } from 'react-router-dom';
import history from '../../utils/history';

import { makeStyles, createStyles } from '@material-ui/core';

import { Route } from 'react-router-dom';

import AllMyGamesAppBar from '../../components/AllMyGamesAppBar';
import AccountSettings from './AccountSettings';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden'
    },
  })
);

interface SettingsPageProps {
  toggleAppDrawer: () => void;
}

export default function SettingsPage(props: SettingsPageProps) {
  const classes = useStyles();

  return (
    <Router history={history}>
      <div className={classes.root}>
        <AllMyGamesAppBar
          config={{
            heading: 'Settings'
          }}
          toggleAppDrawer={props.toggleAppDrawer}
        />
        <div className={classes.content}>
          <Switch>
            <Route
              path="/settings"
              render={() => <AccountSettings />} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}