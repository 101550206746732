import { gql } from 'apollo-boost';
import { DEVELOPER_GAME_MAPPING_FRAGMENT } from './fragments';

export const BACKLOG_QUERY = gql`
  query BACKLOG_QUERY{
    me {
      backlog {
        backlogPosition
        game {
          id
          title
          image
        }
        rating
        state
        provider
      }
    } 
  }
`;



export const DEVELOPER_GAME_MAPPINGS_QUERY = gql`
  query DEVELOPER_GAME_MAPPINGS_QUERY($input: GameMappingsInputType!) {
    developer {
      gameMappings(input: $input) {
        results {
          ...GameMappingFragment
        }
        nextOffset
      }
    }
  }
  ${DEVELOPER_GAME_MAPPING_FRAGMENT}
`;

export const LIBRARY_QUERY = gql`
  query LIBRARY_QUERY {
    me {
      library {
        game {
          id
          title
          image
        }
        isFavorite
        isHidden
        rating
        state
        provider
      }
    } 
  }
`;

export const PROFILE_QUERY = gql`
  query PROFILE_QUERY{
    me {
      displayName
      gogProfileName
      isDeveloper
      isProfilePrivate
      psnProfileName
      steamProfileName
      xboxGamertag
    }
  }
`;

export const SEARCH_GAME_QUERY = gql`
query SEARCH_GAME_QUERY($input: SearchGameQueryInputType!) {
    searchGame(input: $input) {
      games {
        igdbId
        image
        name
      }
    }
  }
`;

export const SOCIAL_FEED_QUERY = gql`
  query SOCIAL_FEED_QUERY {
    feed {
      items {
        displayName
        eventAgeString
        text
      }
    }
  }
`;

export const USER_PUBLIC_PROFILE_QUERY = gql`
  query USER_PUBLIC_PROFILE_QUERY($input: UserQueryInputType!) {
    user(input: $input) {
      backlog {
        backlogPosition
        game {
          id
          title
          image
        }
      }
      collectionStats {
        backlogCount
        completedCount
        libraryCount
        playingCount
        wishlistCount
      }
      displayName
      favorites {
        game {
          id
          title
          image
        }
      }
      isVisitorFollowing
      numFollowers
      numFollowing
    }
  }
`;

export const USER_FOLLOWERS_QUERY = gql`
  query USER_FOLLOWERS_QUERY($input: UserQueryInputType!) {
    user(input: $input) {
      followers {
        displayName
        isVisitorFollowing
      }
    }
  }
`;

export const USER_FOLLOWING_QUERY = gql`
  query USER_FOLLOWING_QUERY($input: UserQueryInputType!) {
    user(input: $input) {
      following {
        displayName
        isVisitorFollowing
      }
    }
  }
`;

export const WISHLIST_QUERY = gql`
  query WISHLIST_QUERY {
    me {
      wishlist {
        game {
          id
          image
          title
        }
        provider
      }
    } 
  }
`;