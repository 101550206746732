/**
 * profile.tsx
 * 
 * This file defines a set of custom React hooks that enable us to retrieve and provide the user's
 * profile data through context. 
 * If a user is not authenticated, they are redirected to the login page.
 * If a user is authenticated but has not completed account setup has not yet setup, 
 * they are redirected to the setup page.
 */

import React, { useState, useContext, useCallback, useEffect } from 'react';
import ProfileData from './../models/ProfileData';
import { PROFILE_QUERY } from '../graphql/queries';
import useQuery from './query-hook';
import SplashScreen from '../components/SplashScreen';
import Setup from '../views/Home/Setup';
import { Profile_QueryQuery, Profile_QueryQueryVariables } from '../generated/graphql-types';
import { ApolloQueryResult } from 'apollo-boost';
import ReactGA from 'react-ga';

export interface ProfileStatus {
  loaded: boolean;
  isSetupComplete: boolean;
}

export const ProfileContext = React.createContext(undefined);
export const useProfile = () => useContext(ProfileContext);
export const ProfileProvider = ({
  children,
  ...initOptions
}) => {
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [profileStatus, setProfileStatus] = useState<ProfileStatus>({
    loaded: false,
    isSetupComplete: false
  });
  const completeSetup = useCallback(() => {
    setProfileStatus({
      loaded: true,
      isSetupComplete: true
    });
  }, [setProfileStatus]);

  const queryCallback = useCallback((result: ApolloQueryResult<Profile_QueryQuery>) => {
    if (result.data.me) {
      setProfileData(result.data.me as ProfileData);
      ReactGA.set({displayName: result.data.me.displayName});
    }
  }, [setProfileData]);

  // Once profileData has been set, set profileStatus
  useEffect(() => {
    if (profileData) {
      let isSetupComplete = 
        profileData.gogProfileName != null ||
        profileData.psnProfileName != null ||
        profileData.steamProfileName != null ||
        profileData.xboxGamertag != null;
      setProfileStatus({
        loaded: true,
        isSetupComplete
      });
    }
  }, [profileData]);

  useQuery<Profile_QueryQuery, Profile_QueryQueryVariables>(PROFILE_QUERY, queryCallback);

  return (
    <ProfileContext.Provider
      value={{
        completeSetup,
        profileData,
        setProfileData
      }}>
      {
        !profileStatus.loaded ? <SplashScreen />
          : !profileStatus.isSetupComplete ? <Setup />
            : children
      }
    </ProfileContext.Provider>
  );

};