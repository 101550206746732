import React, { useState, useCallback } from 'react';
import HomePage from '../views/Home/HomePage';
import { Route, Router, Switch } from 'react-router-dom';

import history from '../utils/history';
import { GraphQLProvider } from '../hooks/graphql';
import UserProfilePage from '../views/UserProfile/UserProfilePage';
import AppDrawer from '../components/AppDrawer';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import SettingsPage from '../views/Settings/SettingsPage';
import { ProfileProvider } from '../hooks/profile';
import SocialFeedPage from '../views/SocialFeed/SocialFeedPage';
import { useAuth } from '../hooks/auth';
import Login from '../components/Identity/Login';
import DeveloperToolsPage from '../views/DeveloperTools/DeveloperToolsPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh'
    },
    content: {
      flexGrow: 1,
      height: '100%',
      maxHeight: '100%'
    },
  })
);

function AllMyGamesRouter() {
  const [appDrawerOpen, setAppDrawerOpen] = useState(false);

  const classes = useStyles();

  const { user } = useAuth();

  const toggleAppDrawer = useCallback(() => {
    setAppDrawerOpen(!appDrawerOpen);
  }, [appDrawerOpen]);

  if (!user) {
    return <Login />;
  }

  return (
    <>
      <GraphQLProvider>
        <ProfileProvider>
          <div className={classes.root}>
            <AppDrawer
              isOpen={appDrawerOpen}
              close={() => setAppDrawerOpen(false)}
            />
            <div className={classes.content}>
              <Router history={history}>
                <Switch>
                  <Route
                    path="/(library|backlog|wishlist)/" exact
                    render={() => <HomePage toggleAppDrawer={toggleAppDrawer} />}
                  />
                  <Route
                    path="/(settings)/" exact
                    render={() => <SettingsPage toggleAppDrawer={toggleAppDrawer} />}
                  />
                  <Route
                    path="/(social)/" exact
                    render={() => <SocialFeedPage toggleAppDrawer={toggleAppDrawer} />}
                  />
                  <Route
                    path="/user/:displayName"
                    render={(props) =>
                      <UserProfilePage
                        displayName={props.match.params.displayName}
                        key={props.match.params.displayName}
                        toggleAppDrawer={toggleAppDrawer}
                      />}
                  />
                  <Route
                    path="/dev" 
                    render={(props) =>
                      <DeveloperToolsPage
                        toggleAppDrawer={toggleAppDrawer}
                      />}
                  />
                </Switch>
              </Router>
            </div>
          </div>
        </ProfileProvider>
      </GraphQLProvider>
    </>
  );
};

export default AllMyGamesRouter;