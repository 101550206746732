import React from 'react';
import { makeStyles, Typography, Link } from '@material-ui/core';
import FollowButton from './FollowButton';
import { Link as RouterLink } from 'react-router-dom';
import FlexGrowBuffer from '../FlexGrowBuffer';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
}));

export interface UserFollowProps {
  displayName: string;
  isVisitorFollowing: boolean;
  visitorDisplayName: string;
}

export default function UserFollow(props: UserFollowProps) {
  const classes = useStyles();

  const followButton = props.visitorDisplayName !== null
    ? (
      <FollowButton 
        displayName={props.displayName}
        isVisitorFollowing={props.isVisitorFollowing} 
        visitorDisplayName={props.visitorDisplayName}/>
    ) : null;
    

  return (
    <div className={classes.root}>
      <Link 
        component={RouterLink} 
        color="textPrimary"
        to={`/user/${props.displayName}`}>
        <Typography variant="body1">{props.displayName}</Typography>
      </Link>
      
      <FlexGrowBuffer />

      { followButton }
    </div>
  );
}