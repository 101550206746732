import React, { useState, useCallback } from 'react';

import { Router, Switch } from 'react-router-dom';
import history from '../../utils/history';

import Library from './Library';
import { makeStyles, createStyles } from '@material-ui/core';

import { Route } from 'react-router-dom';
import Backlog from './Backlog';
import Wishlist from './Wishlist';

import HomeAppBar, { HomeAppBarConfig } from '../../components/Home/HomeAppBar';

import { debounce } from 'lodash';
import AccountSettings from '../Settings/AccountSettings';


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden'
    },
  })
);

export interface HomePageProps {
  toggleAppDrawer: () => void;
}

export default function HomePage(props: HomePageProps) {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const classes = useStyles();

  const handleDrawerClose = useCallback(() => setFilterDrawerOpen(false), [setFilterDrawerOpen]);

  const setSearchQueryThrottled =
    debounce((query: string) => setSearchQuery(query), 100, {
      leading: true,
      trailing: true
    });

  return (
    <Router history={history}>
      <div className={classes.root}>
        <HomeAppBar
          config={getAppBarConfigFromPath(history.location.pathname)}
          setFilterDrawerOpen={setFilterDrawerOpen}
          setSearchQuery={setSearchQueryThrottled}
          toggleAppDrawer={props.toggleAppDrawer}
        />
        <div className={classes.content}>
          <Switch>
            <Route
              path="/(|library)/"
              exact
              render={(props) =>
                <Library {...props}
                  drawerOpen={filterDrawerOpen}
                  onDrawerClose={handleDrawerClose}
                  searchQuery={searchQuery} />}
            />
            <Route
              path="/backlog"
              exact
              render={(props) =>
                <Backlog {...props}
                  drawerOpen={filterDrawerOpen}
                  onDrawerClose={handleDrawerClose}
                  searchQuery={searchQuery} />}
            />
            <Route
              path="/wishlist" exact
              render={(props) =>
                <Wishlist {...props}
                  drawerOpen={filterDrawerOpen}
                  onDrawerClose={handleDrawerClose}
                  searchQuery={searchQuery} />}
            />
            <Route
              path="/settings"
              exact
              render={() => <AccountSettings />} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

function getAppBarConfigFromPath(path: string): HomeAppBarConfig {
  switch (path) {
    case '/':
    case '/library':
      return {
        heading: 'Library',
        isSearchBarEnabled: true,
        isSideDrawerEnabled: true
      };
    case '/backlog':
      return {
        heading: 'Backlog',
        isSearchBarEnabled: true,
        isSideDrawerEnabled: true
      };
    case '/wishlist':
      return {
        heading: 'Wishlist',
        isSearchBarEnabled: true,
        isSideDrawerEnabled: true
      };
    default:
      return {
        heading: '',
        isSearchBarEnabled: false,
        isSideDrawerEnabled: false
      };
  }
}