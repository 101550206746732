import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useMyTheme } from '../hooks/theme';

const useStyles = makeStyles((theme: Theme) => ({
  providerLogo: {
    float: 'right',
    height: size => `${size}px`,
    width: size => `${size}px`
  }
}));

function getProviderLogo(isDarkMode: boolean, provider: string) {
  const logoColor: string = isDarkMode ? 'white' : 'color';
  switch (provider) {
    case 'STEAM':
      return `/images/providers/steam_${logoColor}.png`;
    case 'GOG':
      return `/images/providers/gog_${logoColor}.png`;
    case 'PLAY_STATION':
      return `/images/providers/playstation_${logoColor}.png`;
    case 'XBOX':
      return `/images/providers/xbox_${logoColor}.png`;
    case 'CUSTOM':
    default:
      return `/images/providers/custom_${logoColor}.png`;
  }
}

export interface ProviderLogoProps{
    provider: string;
    size?: string;
}

const getSize = (size: string) => {
  switch(size){
    case 'small':
    default:
      return 20;
    case 'medium':
      return 30;
  }
}

export function ProviderLogo(props: ProviderLogoProps) {
  const { provider, size } = props;
  const { isDarkMode } = useMyTheme();
  const sizeInPx = getSize(size);
  const classes = useStyles(sizeInPx);
  return (
    <img
      className={classes.providerLogo}
      src={getProviderLogo(isDarkMode, provider)} alt='' 
    />
  );
}