/**
 * auth.tsx
 * 
 * This file defines a set of custom React hooks that enable us to work with the
 * oidc-client in a more idomatic way, providing functions that allow the user to
 * log in, log out, and information such as whether the user is logged in.
 */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import history from '../utils/history';

export const AuthContext = React.createContext(undefined);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children
}) => {
  const [bearerToken, setBearerToken] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<object>(null);

  const handleSignout = useCallback(async () => {
    await Auth.signOut().then(() => {
      setBearerToken(null);
      setUser(null);
      history.push('/');
    });
  }, []);

  const updateUserState = useCallback(async () => {
    await Auth.currentAuthenticatedUser()
      .then(authData => {
        setBearerToken((authData as any).signInUserSession.idToken.jwtToken);
        setUser(authData);
      })
      .catch(reason => {
        setBearerToken(null);
        setUser(null);
      });
  }, [setBearerToken, setUser]);

  onAuthUIStateChange((nextAuthState, authData) => {
    updateUserState();
  });

  useEffect(() => {
    updateUserState();
  }, [updateUserState]);

  useEffect(() => {
    setIsAuthenticated(user !== null);
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        bearerToken: bearerToken,
        handleSignout: handleSignout,
        isAuthenticated: isAuthenticated,
        user: user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};