import React from 'react';
import {
  makeStyles, createStyles, Theme, Drawer, Hidden, Divider, List, ListItem, ListItemText, FormControl,
  InputLabel, Select, MenuItem, Typography
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import SortIcon from '@material-ui/icons/Sort';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { LibraryGameState } from '../../generated/graphql-types';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerNavList: {
      flexGrow: 1
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 500
    },
    drawerRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      zIndex: 500
    },
    expanded: {
      '&$expanded': {
        margin: '0'
      }
    },
    accordion: {
      margin: 0
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    heading: {
      marginLeft: theme.spacing(2)
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar
  })
);

export type GameStateFilterValue = 'ALL' | LibraryGameState;

type LibraryDrawerProps = {
  className: string,
  gameStateFilter: GameStateFilterValue,
  handleGameStateFilterChange: (gameStateFilter: GameStateFilterValue) => void,
  loading: boolean,
  open: boolean,
  onPressRefresh: () => void,
  onPressAddCustom: () => void,
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void,
  sortOrder: string,
  handleSortOrderChange: (sortOrder: string) => void,
  sortType: string,
  handleSortTypeChange: (sortType: string) => void,
  favoritesFilterEnabled: boolean,
  handleFavoritesFilterEnabledChange: (isEnabled: boolean) => void,
  hiddenFilterEnabled: boolean,
  handleHiddenFilterEnabledChange: (isEnabled: boolean) => void,
  showFiltersAndSorts: boolean,
  showAddCustom: boolean
}

export default function LibraryDrawer(props: LibraryDrawerProps) {
  const classes = useStyles();

  const filtersAndSorts = (
    <>
      <Accordion
        defaultExpanded
        square
        classes={{ expanded: classes.expanded }}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filter-panel-content"
          id="filter-panel-header"
        >
          <FilterListIcon />
          <Typography className={classes.heading}>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={props.favoritesFilterEnabled}
                  onChange={(event) => props.handleFavoritesFilterEnabledChange(event.target.checked)}
                  name="favoritesFilterSwitch"
                  color="primary"
                />
              }
              label="Favorites only"
            />
          </FormGroup>

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={props.hiddenFilterEnabled}
                  onChange={(event) => props.handleHiddenFilterEnabledChange(event.target.checked)}
                  name="hiddenFilterSwitch"
                  color="primary"
                />
              }
              label="Show hidden"
            />
          </FormGroup>

          <FormControl className={classes.formControl}>
            <InputLabel id="library-filter-state-select-label">Game state</InputLabel>
            <Select
              defaultValue={'ALL'}
              labelId="library-filter-state-select-label"
              id="library-filter-state-select"
              onChange={({ target }) => props.handleGameStateFilterChange(target.value as (GameStateFilterValue))}
              value={props.gameStateFilter}
            >
              <MenuItem value={'ALL'}>All</MenuItem>
              {
                Object.values(LibraryGameState).map(state =>
                  <MenuItem key={state} value={state}>
                    {getDisplayNameForLibraryGameState(state)}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        square
        classes={{ expanded: classes.expanded }}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="sort-panel-content"
          id="sort-panel-header"
        >
          <SortIcon />
          <Typography className={classes.heading}>Sort</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <FormControl className={classes.formControl}>
            <InputLabel id="library-sort-type-select-label">Sort type</InputLabel>
            <Select
              defaultValue="name"
              labelId="library-sort-type-select-label"
              id="library-sort-type-select"
              value={props.sortType}
              onChange={({ target }) => props.handleSortTypeChange(target.value as string)}
            >
              <MenuItem value={'name'}>Name</MenuItem>
              <MenuItem value={'rating'}>Rating</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="library-sort-order-select-label">Sort order</InputLabel>
            <Select
              defaultValue="asc"
              labelId="library-sort-order-select-label"
              id="library-sort-order-select"
              value={props.sortOrder}
              onChange={({ target }) => props.handleSortOrderChange(target.value as string)}
            >
              <MenuItem value={'asc'}>Ascending</MenuItem>
              <MenuItem value={'desc'}>Descending</MenuItem>
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </>
  );

  const drawer = (
    <>
      <List>
        <ListItem button
          key='refresh'
          disabled={props.loading}
          onClick={props.onPressRefresh}
        >
          <ListItemIcon>
            <RefreshIcon />
          </ListItemIcon>
          <ListItemText primary='Refresh games' />
        </ListItem>
        
        {props.showAddCustom &&
          <ListItem button
            key='add-custom'
            disabled={props.loading}
            onClick={props.onPressAddCustom}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary='Add custom game' />
          </ListItem>
        }
      </List>

      {props.showFiltersAndSorts && filtersAndSorts }
    </>
  );

  return (
    <div className={props.className}>
      <nav className={classes.drawerRoot}>
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            anchor="right"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <Divider />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

function getDisplayNameForLibraryGameState(state: LibraryGameState): string {
  switch (state) {
    case LibraryGameState.Unplayed:
      return 'Unplayed';
    case LibraryGameState.Backlog:
      return 'Backlog';
    case LibraryGameState.Playing:
      return 'Playing';
    case LibraryGameState.Completed:
      return 'Completed';
    case LibraryGameState.WontPlay:
      return "Won't Play";
    case LibraryGameState.Paused:
      return 'Paused';
    case LibraryGameState.Abandoned:
      return 'Abandoned';
    default:
      return LibraryGameState[state] || '';
  }
}