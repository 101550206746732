import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loadingPaneRoot: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
}));

export default function LoadingPane() {
  const classes = useStyles();

  return (
    <div className={classes.loadingPaneRoot}>
      <CircularProgress />
    </div>
  )
}