
import { ApolloQueryResult } from 'apollo-boost';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Developer_Game_Mappings_QueryQuery, 
  Developer_Game_Mappings_QueryQueryVariables, 
  GameMappingType 
} from '../../generated/graphql-types';
import { useDeferredQuery } from '../../hooks/query-hook';
import { DEVELOPER_GAME_MAPPINGS_QUERY } from '../../graphql/queries';
import { makeStyles } from '@material-ui/core';
import IgdbGameMappingTable from '../../components/Developer/IgdbGameMappingTool/IgdbGameMappingTable';
import SplashScreen from '../../components/SplashScreen';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  }
}));

const GAME_MAPPING_BATCH_SIZE = 50;

export default function IgdbGameMappingTool() {
  const classes = useStyles();

  const [gameMappings] = useState<GameMappingType[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const handleCompletedQuery = useCallback((result: ApolloQueryResult<Developer_Game_Mappings_QueryQuery>) => {
    if (result.data.developer.gameMappings) {
      gameMappings.push(...result.data.developer.gameMappings.results);
      setNextOffset(result.data.developer.gameMappings.nextOffset);
      setItemCount(gameMappings.length);
    }
  }, [gameMappings]);
  
  const { 
    execute: queryExecute, 
    loading: queryLoading,
  } = 
    useDeferredQuery<
      Developer_Game_Mappings_QueryQuery, 
      Developer_Game_Mappings_QueryQueryVariables
    >(
      DEVELOPER_GAME_MAPPINGS_QUERY, 
      handleCompletedQuery,
    );

  const handleLoadNextPage = useCallback(() => {
    queryExecute({
      input: {
        skip: nextOffset,
        take: GAME_MAPPING_BATCH_SIZE,
      }
    })
  }, [nextOffset, queryExecute]);

  useEffect(() => {
    // Load the first page
    queryExecute({
      input: {
        skip: 0,
        take: GAME_MAPPING_BATCH_SIZE,
      }
    });
  }, [queryExecute]);

  if (itemCount === 0) {
    return <SplashScreen message={'Loading Game Mappings...'} />;
  } 

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <IgdbGameMappingTable 
          hasNextPage={nextOffset >= 0}
          isNextPageLoading={queryLoading}
          itemCount={itemCount}
          items={gameMappings}
          loadNextPage={handleLoadNextPage}
        />
      </div>
    </div>
  )
};