import React from 'react';
import { Grid } from '@material-ui/core';
import FeatureTile from '../../components/Landing/FeatureTile';

export default function FeatureGrid() {
  return (
    <Grid container spacing={5} wrap="wrap" justify="center">
      <Grid item>
        <FeatureTile
          title="Import Games"
          description={`
            Automatically sync games from your Steam, GOG, PlayStation, 
            and Xbox collections into one cross-platform library.
          `}
          imageUri={'/images/landing/import_games.svg'}
        />
      </Grid>
      <Grid item>
        <FeatureTile
          title="Stay Organized"
          description={`
            Prioritize the games you've been meaning to play, track your cross-platform wishlist, 
            and cut through the clutter to find your next favorite game.
          `}
          imageUri={'/images/landing/stay_organized.svg'}
        />
      </Grid>
      <Grid item>
        <FeatureTile
          title="Access Anywhere"
          description={`
            AllMyGames works with any web browser, so your games are always available whether you're on the couch,
            at your desk, or on the go.
          `}
          imageUri={'/images/landing/access_anywhere.svg'}
        />
      </Grid>
    </Grid>
  );
}