import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface FullBleedImageProps {
  height: number;
  minWidth: number;
  url: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      backgroundColor: 'black',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: (dimensions: FullBleedImageProps) => `${dimensions.height}px`,
      minWidth: (dimensions: FullBleedImageProps) => `${dimensions.minWidth}px`,
    }
  }));

export default function FullBleedImage(props: FullBleedImageProps) {
  const classes = useStyles(props);

  return (
    <div 
      className={classes.image}
      style={{ backgroundImage: `url(${props.url})` }}>
    </div>
  );
}