import React, { ReactElement, useMemo } from 'react';
import {
  makeStyles, createStyles, Drawer, Hidden, Divider, List, ListItem, ListItemText, useTheme, ListItemIcon,
  Icon, Theme
} from '@material-ui/core';
import { useAuth } from '../hooks/auth';
import { Link } from 'react-router-dom';
import BookIcon from '@material-ui/icons/Book';
import GitHubIcon from '@material-ui/icons/GitHub';
import SettingsIcon from '@material-ui/icons/Settings';
import { useProfile } from '../hooks/profile';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootNav: {
      [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: drawerWidth
      }
    },
    brand: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      height: 56,
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        height: 48,
      },
      [theme.breakpoints.up('sm')]: {
        height: 64,
      }
    },
    drawerNavList: {
      flexGrow: 1
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    iconRoot: {
      textAlign: 'center'
    },
    imageIcon: {
      height: '100%'
    }
  })
);

interface AppDrawerListItemModel {
  key: string,
  path: string
  icon?: ReactElement,
}

interface AppDrawerProps {
  close: () => void,
  isOpen: boolean,
}

export default function AppDrawer(props: AppDrawerProps) {
  const classes = useStyles();
  const theme = useTheme();

  const { handleSignout } = useAuth();
  const { profileData } = useProfile();

  const topListItemModels: AppDrawerListItemModel[] = useMemo(() => [
    { key: 'Library', path: '/library' },
    { key: 'Backlog', path: '/backlog' },
    { key: 'Wishlist', path: '/wishlist' },
    { key: 'Profile', path: `/user/${profileData.displayName}` },
    { key: 'Social Feed', path: '/social' },
    { key: 'Settings', path: '/settings', icon: <SettingsIcon /> }
  ], [profileData.displayName]);

  const bottomListItemModels: AppDrawerListItemModel[] = useMemo(() => {
    let models: AppDrawerListItemModel[] = [
      { key: 'Block', path: 'https://blog.allmy.games', icon: <BookIcon />},
      { 
        key: 'Discord', 
        path: 'https://discord.gg/XAbAFjz', 
        icon: 
        <Icon classes={{ root: classes.iconRoot }}>
          <img className={classes.imageIcon} src='/images/icons/discord-logo-color.svg' alt='' />
        </Icon>
      },
      { key: 'GitHub', path: 'https://github.com/allmygames', icon: <GitHubIcon /> }
    ];

    if (profileData.isDeveloper) {
      models.push({ key: 'Developer Tools', path: '/dev' });
    }

    return models;
  }, [classes.iconRoot, classes.imageIcon, profileData.isDeveloper]);

  const drawer = (
    <div className={classes.drawerRoot}>
      <div className={classes.brand}>
        <img src='../images/logo_pixel.svg' alt='' height='32' />
      </div>

      <Divider />

      <List className={classes.drawerNavList}>
        {
          topListItemModels.map(x => {
            let icon = x.icon ? <ListItemIcon>{x.icon}</ListItemIcon> : null;
            return (
              <ListItem button key={x.key} component={Link} to={x.path} onClick={props.close}>
                {icon}
                <ListItemText primary={x.key} />
              </ListItem>
            )
          })
        }
      </List>

      <Divider />
      
      <List>
        {
          bottomListItemModels.map(x => {
            let icon = x.icon ? <ListItemIcon>{x.icon}</ListItemIcon> : null;
            return (
              <ListItem button component="a" href={x.path} key={x.key}>
                {icon}
                <ListItemText primary={x.key} />
              </ListItem>
            )
          })
        }
      </List>

      <Divider />
      
      <List>
        <ListItem button key='logout' onClick={handleSignout}>
          <ListItemText primary='Logout' />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.rootNav}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.isOpen}
          onClose={props.close}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  )
}