import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1
  }
}));

export default function FlexGrowBuffer() {
  const classes = useStyles();
  return (
    <div className={classes.flexGrow} />
  );
}