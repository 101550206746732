/**
 * Backlog.tsx
 * 
 * This component is responsible for displaying the Backlog page.
 */

import React, { useState, useCallback } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { makeStyles, CircularProgress, Typography } from '@material-ui/core';
import GameCard, { GameCardProps } from '../../components/GameCard';
import { BACKLOG_QUERY } from '../../graphql/queries';
import { UPDATE_BACKLOG_POSITION_MUTATION } from '../../graphql/mutations';
import useQuery from '../../hooks/query-hook';
import LibraryDrawer from '../../components/Home/LibraryDrawer';
import { useMutation } from '@apollo/react-hooks';
import {
  Backlog_QueryQuery,
  Backlog_QueryQueryVariables,
  Update_Backlog_Position_MutationMutation,
  Update_Backlog_Position_MutationMutationVariables,
  BacklogEntryType,
  LibraryGameState
} from '../../generated/graphql-types';
import { ApolloQueryResult } from 'apollo-boost';
import SelectedGameModal from '../../components/SelectedGameModal';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    maxHeight: '100%',
    overflowY: 'scroll',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  flexWrapContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  gamesHeader: {
    color: 'rgba(255,255,255,.5)',
    textAlign: 'center'
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50px'
  },
  root: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
  },
}));

interface BacklogProps {
  drawerOpen: boolean,
  onDrawerClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void,
  searchQuery: string
}

export default function Backlog(props: BacklogProps) {
  const classes = useStyles();
  
  const [open, setOpen] = useState<boolean>(false);
  const [backlogGames, setBacklogGames] = useState<GameCardProps[]>(null);
  const [playingGames, setPlayingGames] = useState<GameCardProps[]>(null);
  const [selectedGame, setSelectedGame] = useState<GameCardProps>(null);

  const handleOpen = useCallback((game: GameCardProps) => {
    setSelectedGame(game);
    setOpen(true);
  }, [setSelectedGame, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedGame(null);
  }, [setSelectedGame, setOpen]);

  const buildGameCardProps = useCallback((entry: BacklogEntryType) => {
    return {
      key: entry.game.id,
      id: entry.game.id,
      title: entry.game.title,
      favoriteToggleEnabled: false,
      image: entry.game.image,
      isFavorite: false,
      isHidden: false,
      isWishlist: false,
      provider: entry.provider,
      rating: entry.rating,
      state: entry.state,
      backlogPosition: entry.backlogPosition,
      get onClick() {
        return () => handleOpen(this);
      },
      get toggleFavorite() {
        return null;
      },
      get updateRating() {
        return null;
      },
      ratingControlEnabled: false
    } as GameCardProps;
  }, [handleOpen]);

  const categorizeGames = useCallback((games?: GameCardProps[]) => {
    games = games || backlogGames.concat(playingGames);

    let gamesInBacklog = games.filter(x => x.state === LibraryGameState.Backlog).sort((a, b) => a.backlogPosition - b.backlogPosition);
    for (let i = 0; i < gamesInBacklog.length; i++) {
      // Update backlog position to account for gaps or shifts as the result of games being added or removed from backlog in this session
      gamesInBacklog[i].backlogPosition = i + 1;
    }
    setBacklogGames(gamesInBacklog);
    setPlayingGames(games.filter(x => x.state === LibraryGameState.Playing));
  }, [backlogGames, playingGames]);

  const queryCallback = useCallback((result: ApolloQueryResult<Backlog_QueryQuery>) => {
    if (result.data.me) {
      let games = result.data.me.backlog.map(buildGameCardProps);
      categorizeGames(games);
    }
  }, [buildGameCardProps, categorizeGames]);

  const { loading, reload } = useQuery<Backlog_QueryQuery, Backlog_QueryQueryVariables>(BACKLOG_QUERY, queryCallback);
  const [updateBacklogPosition] =
    useMutation<Update_Backlog_Position_MutationMutation, Update_Backlog_Position_MutationMutationVariables>(
      UPDATE_BACKLOG_POSITION_MUTATION);

  const handleSortEnd = useCallback((sort) => {
    let backlogGamesCopy = backlogGames.slice();
    let movedGame = backlogGamesCopy[sort.oldIndex];
    backlogGamesCopy.splice(sort.oldIndex, 1);
    backlogGamesCopy.splice(sort.newIndex, 0, movedGame);
    
    for (let i = Math.min(sort.oldIndex, sort.newIndex); i < backlogGamesCopy.length; i++) {
      backlogGamesCopy[i].backlogPosition = i + 1;
    }
    
    // Update server state
    // TODO: Handle failures? Await response before allowing additional changes?
    updateBacklogPosition({
      variables: {
        backlogPosition: sort.newIndex + 1,
        gameId: movedGame.id
      }
    });
    
    setBacklogGames(backlogGamesCopy);
  }, [backlogGames, updateBacklogPosition]);

  const SortableGame = SortableElement(({ game }) => <GameCard {...game} />);
  const SortableGamesGrid = SortableContainer(({ items }) => {
    return (
      <div className={classes.flexWrapContainer}>
        {items.map((game, index) =>
          <SortableGame key={game.id} index={index} game={game} />
        )}
      </div>
    )
  });

  const loadingSpinner = loading
    ? (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
    : null;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          { loadingSpinner }

          {!loading && playingGames && playingGames.length !== 0 &&
            <>
              <Typography variant="h6">Playing</Typography>
              <div className={classes.flexWrapContainer}>
                {playingGames.map(gameCardProps => <GameCard {...gameCardProps} />)}
              </div>
            </>
          }

          {!loading && backlogGames && backlogGames.length !== 0 &&
            <>
              <Typography variant="h6">Backlog</Typography>
              <div>
                <SortableGamesGrid items={backlogGames} axis="xy"
                  useDragHandle={true}
                  onSortEnd={handleSortEnd}
                />
              </div>
            </>
          }
        </div>

        <LibraryDrawer
          className={classes.drawer}
          favoritesFilterEnabled={false}
          gameStateFilter={'ALL'}
          handleFavoritesFilterEnabledChange={() => { }}
          hiddenFilterEnabled={true}
          handleHiddenFilterEnabledChange={() => { }}
          handleGameStateFilterChange={() => { }}
          handleSortOrderChange={() => { }}
          handleSortTypeChange={() => { }}
          loading={loading}
          onClose={props.onDrawerClose}
          onPressAddCustom={() => { }}
          onPressRefresh={reload}
          open={props.drawerOpen}
          showAddCustom={false}
          showFiltersAndSorts={false}
          sortOrder={''}
          sortType={''}
        />
      </div>

      <SelectedGameModal
        disableFavoriteAndRating
        onClose={handleClose}
        onDelete={() => {
          setOpen(false);
          reload();
        }}
        onGameStateChange={() => {
          categorizeGames();
        }}
        open={open}
        selectedGame={selectedGame}
      />
    </>
  );
};