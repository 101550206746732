import React, { useCallback, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_FOLLOWING_MUTATION } from '../../graphql/mutations';
import { Update_Following_MutationMutationVariables, Update_Following_MutationMutation } from '../../generated/graphql-types';

const useStyles = makeStyles((theme) => ({
  followButton: {
    width: '110px'
  }
}));

export interface FollowButtonProps {
  displayName: string;
  isVisitorFollowing: boolean;
  visitorDisplayName: string;
}

export default function FollowButton(props: FollowButtonProps) {
  const classes = useStyles();

  const [isFollowing, setIsFollowing] = useState<boolean>(props.isVisitorFollowing);

  const [updateFollowing] = useMutation<Update_Following_MutationMutation, Update_Following_MutationMutationVariables>(
    UPDATE_FOLLOWING_MUTATION, {
      variables: {
        input: {
          makeFollow: !isFollowing,
          targetDisplayName: props.displayName
        }
      }
    });

  const toggleFollowing = useCallback(() => {
    updateFollowing();
    setIsFollowing(!isFollowing);
  }, [isFollowing, updateFollowing]);

  if (!props.visitorDisplayName || props.visitorDisplayName === props.displayName) {
    return null;
  }

  return (
    <Button 
      className={classes.followButton}
      variant="contained"
      onClick={toggleFollowing}
      color={ isFollowing ? 'primary' : 'default' }>
      { isFollowing ? 'Following' : 'Follow' }
    </Button>
  );
}