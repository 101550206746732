/**
 * GameCard.tsx
 * 
 * This component is responsible for displaying a GameCard.
 */

import React from 'react';
import { makeStyles, Card, Divider, Theme } from '@material-ui/core';
import ClampLines from 'react-clamp-lines';
import { ProviderLogo } from './ProviderLogo';
import { FavoriteControl } from './FavoriteControl';
import { SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import FullBleedImage from './FullBleedImage';
import Rating from '@material-ui/lab/Rating';

const CardHeight = 150;
const CardWidth = 310;
const ImageWidth = 105;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    height: `${CardHeight}px`,
    margin: '5px',
    [theme.breakpoints.down('xs')]: {
      flex: `1 1 ${CardWidth}px`,
    },
    [theme.breakpoints.up('sm')]: {
      width: `${CardWidth}px`,
    },
    userSelect: 'none'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    width: '100%'
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  footerStartWrapper: {
    flexGrow: 1,
    height: '24px'
  },
  title: {
    flexGrow: 1,
    fontSize: '1.2rem',
    margin: theme.spacing(1)
  }
}));

const DragHandle = SortableHandle(() =>
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <DragIndicatorIcon />
  </div>
);

export type GameCardProps = {
  backlogPosition?: number, // TODO: Move to an extension of GameCardProps for Backlog
  id: string,
  isFavorite: boolean,
  isHidden: boolean,
  isWishlist: boolean,
  title: string,
  image: string,
  provider: string,
  rating: number,
  state: string,
  onClick: (event: React.MouseEvent) => void,
  toggleFavorite: (event: React.MouseEvent) => void,
  updateRating: (event: React.MouseEvent, value: number) => void,
  favoriteToggleEnabled: boolean,
  ratingControlEnabled: boolean
}

export default function GameCard(props: GameCardProps) {
  const classes = useStyles();
  const showBacklogPosition: boolean = props.backlogPosition && props.backlogPosition > 0;

  const dragHandle = props.backlogPosition > 0
    ? <DragHandle />
    : null;

  const favoriteToggle = props.favoriteToggleEnabled
    ? (
      <FavoriteControl
        isFavorite={props.isFavorite}
        toggleFavorite={props.toggleFavorite}
      />
    )
    : null;

  const ratingControl = props.ratingControlEnabled
    ? (
      <Rating
        name={`game-card-rating-${props.id}`}
        onClick={(e) => { e.stopPropagation(); }}
        onChange={props.updateRating}
        size="medium"
        value={props.rating}
      />
    )
    : null;

  return (
    <Card className={classes.root} onClick={props.onClick}>
      {dragHandle}

      <FullBleedImage
        height={CardHeight}
        minWidth={ImageWidth}
        url={props.image}
      />

      <div className={classes.content}>
        <ClampLines
          id={props.title}
          text={props.title}
          lines={4}
          ellipsis="..."
          buttons={false}
          className={classes.title}
          innerElement="div"
        />

        <Divider />

        <div className={classes.footer}>
          <span hidden={!showBacklogPosition}>
            {`#${props.backlogPosition}`}
          </span>

          {favoriteToggle}
          
          {ratingControl}

          <ProviderLogo
            provider={props.provider}
          />
        </div>
      </div>

    </Card>
  )
}