import { 
  Toolbar, IconButton, makeStyles, Theme, createStyles, Typography 
} from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SearchBar from '../SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 501,
    },
    appBarContentLeft: {
      flexGrow: 1, 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    libraryDrawerButton: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  })
);

export interface HomeAppBarConfig {
  heading: string;
  isSearchBarEnabled: boolean;
  isSideDrawerEnabled: boolean;
}

interface HomeAppBarProps {
  config: HomeAppBarConfig;
  setFilterDrawerOpen: (boolean) => void;
  setSearchQuery: (string) => void;
  toggleAppDrawer: () => void;
}

export default function HomeAppBar(props: HomeAppBarProps) {
  const classes = useStyles();

  const searchBar =
    props.config.isSearchBarEnabled
      ? <SearchBar setSearchQuery={props.setSearchQuery} />
      : null;

  const sideDrawer = 
    props.config.isSideDrawerEnabled
      ? (
        <IconButton edge="end"
          className={classes.libraryDrawerButton}
          color="inherit" aria-label="filter" onClick={() => props.setFilterDrawerOpen(true)}>
          <MenuOpenIcon />
        </IconButton>
      ) 
      : null;

  return (
    <Toolbar className={classes.appBar}>
      <div className={classes.appBarContentLeft}>
        <IconButton 
          className={classes.menuButton}
          aria-label="menu"
          color="inherit" 
          edge="start" 
          onClick={props.toggleAppDrawer}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">
          {props.config.heading}  
        </Typography>
      </div>
      { searchBar }
      { sideDrawer }
    </Toolbar>
  );
}