/**
 * GameGrid.tsx
 * 
 * This component is responsible for displaying a collection of games in a grid layout.
 */

import React, { useState } from 'react';
import GameCard, { GameCardProps } from './GameCard';
import { FixedSizeGrid } from 'react-window';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden'
  }
}));

interface GameGridProps {
  games: GameCardProps[],
  height: number,
  width: number
}

export default function GameGrid(props: GameGridProps) {
  const classes = useStyles();
  const [numColumns, setNumColumns] = useState<number>(undefined);

  const targetColumnWidth = 320;
  const columnCount = Math.floor(props.width / targetColumnWidth);

  const columnWidth = (columnCount === 1) ? props.width : targetColumnWidth;

  if (!numColumns || numColumns !== columnCount) {
    // Update number of columns in component state to enable lookup of GameCard props by
    // row and column indices
    setNumColumns(columnCount);
  }

  const rowCount = Math.ceil(props.games.length / columnCount);
  const rowHeight = 160;
  return (
    <FixedSizeGrid
      class={classes.root}
      columnCount={columnCount}
      columnWidth={columnWidth}
      height={props.height}
      rowCount={rowCount}
      rowHeight={rowHeight}
      width={props.width}
    >
      {
        ({ columnIndex, rowIndex, style }) =>
        {
          // Render a game in this cell if one exists
          const gameIndex = rowIndex * numColumns + columnIndex;
          const game = gameIndex < props.games.length ? props.games[gameIndex] : null;
          if (!game) {
            return null;
          }

          return (
            <div style={style}>
              <GameCard {...game} />
            </div>
          );
        }
      }
    </FixedSizeGrid>
  );
}