import React, { useCallback, useMemo, useState } from 'react';
import {
  Card, Typography, Button, makeStyles, Theme, createStyles, CardActions, CardContent, Grid, Link
} from '@material-ui/core';
import useQuery from '../../hooks/query-hook';
import { USER_PUBLIC_PROFILE_QUERY } from '../../graphql/queries';
import {
  User_Public_Profile_QueryQuery, User_Public_Profile_QueryQueryVariables
} from '../../generated/graphql-types';
import { ApolloQueryResult } from 'apollo-boost';
import { Redirect } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import FollowButton from '../../components/UserProfile/FollowButton';
import { useProfile } from '../../hooks/profile';
import LoadingPane from '../../components/LoadingPane';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import FlexGrowBuffer from '../../components/FlexGrowBuffer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    followButton: {
      marginLeft: 'auto'
    },
    profileCard: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
      },
    },
    profileCardContent: {
      display: 'flex',
      marginTop: '5px'
    },
    profileRoot: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '720px'
    }
  })
);

interface ProfileProps {
  displayName: string;
}

export default function Profile(props: ProfileProps) {
  const classes = useStyles();

  const history = useHistory();
  const { profileData: visitorProfileData } = useProfile();

  const [profileUser, setProfileUser] = useState<User_Public_Profile_QueryQuery>(null);

  const profileQueryCallback = useCallback((result: ApolloQueryResult<User_Public_Profile_QueryQuery>) => {
    setProfileUser(result.data);
  }, [setProfileUser]);

  const { loading: profileLoading } =
    useQuery<User_Public_Profile_QueryQuery, User_Public_Profile_QueryQueryVariables>(USER_PUBLIC_PROFILE_QUERY, profileQueryCallback,
      { input: { displayName: props.displayName } });

  const backlogGames = useMemo(() => {
    if (!profileUser?.user?.backlog) {
      return null;
    }

    return profileUser.user.backlog
      .filter(x => x.backlogPosition !== 0)
      .sort((a, b) => a.backlogPosition - b.backlogPosition);
  }, [profileUser]);

  if (profileLoading || profileUser === null) {
    return <LoadingPane />;
  }

  if (profileUser.user == null) {
    // User does not exist or profile is marked as private - redirect to home page
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.profileRoot}>
      {
        (visitorProfileData.displayName === profileUser.user.displayName && visitorProfileData.isProfilePrivate) &&
        <Alert severity="warning">
          Your profile is private and can not be viewed by others.
            You can change this on the <Link component={RouterLink} to="/settings" color="textPrimary">Settings</Link> page.
        </Alert>
      }
      <Card className={classes.profileCard}>
        <CardContent>
          <Typography
            variant="h4">
            {props.displayName}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => history.push(`/user/${props.displayName}/followers`)}>
            {`${profileUser.user.numFollowers} Followers`}
          </Button>
          <Button onClick={() => history.push(`/user/${props.displayName}/following`)}>
            {`${profileUser.user.numFollowing} Following`}
          </Button>

          <FlexGrowBuffer />

          <FollowButton
            displayName={profileUser.user.displayName}
            isVisitorFollowing={profileUser.user.isVisitorFollowing}
            visitorDisplayName={visitorProfileData.displayName} />
        </CardActions>
      </Card>

      <Card className={classes.profileCard}>
        <CardContent>
          <Typography
            variant="h5">
            Collection
          </Typography>

          <div className={classes.profileCardContent}>
            <Grid container spacing={1}>
              <Grid item xs={12}>{`${profileUser.user.collectionStats.libraryCount} Games in Library`}</Grid>
              <Grid item xs={6}>{`Backlog: ${profileUser.user.collectionStats.backlogCount}`}</Grid>
              <Grid item xs={6}>{`Backlog: ${profileUser.user.collectionStats.wishlistCount}`}</Grid>
              <Grid item xs={6}>{`Playing: ${profileUser.user.collectionStats.playingCount}`}</Grid>
              <Grid item xs={6}>{`Completed: ${profileUser.user.collectionStats.completedCount}`}</Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.profileCard}>
        <CardContent>
          <Typography
            variant="h5">
            Currently Playing
          </Typography>
          <div className={classes.profileCardContent}>
            <Grid container spacing={1}>
              {profileUser.user.backlog.filter(x => x.backlogPosition === 0).map(x =>
                <Grid item>
                  <img src={x.game.image} alt={x.game.title} title={x.game.title} height={150} />
                </Grid>
              )}
            </Grid>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.profileCard}>
        <CardContent>
          <Typography
            variant="h5">
            Backlog
          </Typography>
          <div className={classes.profileCardContent}>
            <Grid container spacing={1}>
              {backlogGames.map(x =>
                <Grid item>
                  <img src={x.game.image} alt={x.game.title} title={x.game.title} height={150} />
                </Grid>
              )}
            </Grid>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.profileCard}>
        <CardContent>
          <Typography
            variant="h5">
            Favorites
          </Typography>
          <div className={classes.profileCardContent}>
            <Grid container spacing={1}>
              {profileUser.user.favorites.map(x =>
                <Grid item>
                  <img src={x.game.image} alt={x.game.title} title={x.game.title} height={150} />
                </Grid>
              )}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}