import React from 'react';
import { Typography, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((them) => 
  createStyles({
    featureTile: {
      display: 'flex',
      flexDirection: 'column',
      width: '200px'
    },
    icon: {
      height: '64px'
    }
  }));

export interface FeatureTileProps {
  description: string;
  imageUri: string;
  title: string;
}

export default function FeatureTile(props: FeatureTileProps) {
  const classes = useStyles();

  return (
    <div className={classes.featureTile}>
      <img src={props.imageUri} alt="" className={classes.icon} />
      <Typography 
        variant="h6"
        align="center">
        {props.title}
      </Typography>
      <Typography variant="body2" align="center">
        {props.description}
      </Typography>
    </div>
  )
}