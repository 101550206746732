import React, { useState, useCallback } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { 
  Update_Profile_Privacy_MutationMutation, Update_Profile_Privacy_MutationMutationVariables 
} from '../../generated/graphql-types';
import { UPDATE_PROFILE_PRIVACY_MUTATION } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'apollo-boost';

export interface ProfilePrivacyCheckboxProps {
  initialValue: boolean;
  onValueChange: (value: boolean) => void;
}

export default function ProfilePrivacyCheckbox(props: ProfilePrivacyCheckboxProps) {
  const [inputValue, setInputValue] = useState<boolean>(props.initialValue);
  const [updating, setUpdating] = useState<boolean>(false);

  const [updateProfilePrivacy] = 
    useMutation<Update_Profile_Privacy_MutationMutation, Update_Profile_Privacy_MutationMutationVariables>(UPDATE_PROFILE_PRIVACY_MUTATION);

  const handleValueChange = useCallback((value: boolean) => {
    setUpdating(true);
    setInputValue(value);
    updateProfilePrivacy({
      variables: {
        input: {
          makeProfilePrivate: value
        }
      }
    }).then(
      (result: ExecutionResult<Update_Profile_Privacy_MutationMutation>) => {
        let isPrivate = result.data.updateProfilePrivacy.isProfilePrivate;
        if (isPrivate !== value) {
          // If value returned does not match input, revert local state
          setInputValue(isPrivate);
        }

        props.onValueChange(isPrivate);
      },
      () => {
        // If failure response received, revert local state
        setInputValue(!value);
      }
    ).finally(() => {
      setUpdating(false);
    });
  }, [props, updateProfilePrivacy]);
  const handleChange = useCallback((e, checked) => handleValueChange(checked), [handleValueChange]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={inputValue}
          disabled={updating}
          onChange={handleChange}
          name="checkedB"
          color="primary"
        />
      }
      label="Private Profile"
    />
  );
}