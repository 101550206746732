import React, { useCallback, useMemo, useState } from 'react';
import { 
  Developer_Refetch_Game_MappingMutation, 
  Developer_Refetch_Game_MappingMutationVariables, 
  Developer_Update_Game_MappingMutation, 
  Developer_Update_Game_MappingMutationVariables, 
  Developer_Verify_Game_MappingMutation, 
  Developer_Verify_Game_MappingMutationVariables, 
  GameMappingType 
} from '../../../generated/graphql-types';

import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { 
  DEVELOPER_REFETCH_GAME_MAPPING, 
  DEVELOPER_UPDATE_GAME_MAPPING, 
  DEVELOPER_VERIFY_GAME_MAPPING,
} from '../../../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  button: {
    flex: '0 0 100px'
  },
  gameMappingDescription: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    overflow: 'none'
  },
  mappingInputs: {
    display: 'flex',
    flex: '0 0 220px'
  },
  scoreWrapper: {
    flex: '0 0 85px',
    textAlign: 'center'
  },
  tableRow: {
    display: 'flex',
    borderBottom: 'solid 1px white',
    height: '100%',
    alignItems: 'center',
  },
  tableRowCell: {
    marginLeft: '1em'
  },
}));

interface IgdbGameMappingTableRowProps {
  data: GameMappingType[];
  index: number;
  style: object;
}

export default function IgdbGameMappingTableRow(props: IgdbGameMappingTableRowProps) {
  const classes = useStyles();

  const [gameMapping, setGameMapping] = useState<GameMappingType>(props.data[props.index]);
  const [updating, setUpdating] = useState<boolean>(false);

  const refetchGameMappingCallback = useCallback((result: Developer_Refetch_Game_MappingMutation) => {
    if (result.developer.refetchGameMapping) {
      setGameMapping(result.developer.refetchGameMapping);
    }
    setUpdating(false);
  }, [setGameMapping, setUpdating]);

  const updateGameMappingCallback = useCallback((result: Developer_Update_Game_MappingMutation) => {
    if (result.developer.updateGameMapping) {
      setGameMapping(result.developer.updateGameMapping);
      setUpdating(false);
    }
  }, [setGameMapping, setUpdating]);

  const [updateGameMapping] =
    useMutation<Developer_Update_Game_MappingMutation, Developer_Update_Game_MappingMutationVariables>(
      DEVELOPER_UPDATE_GAME_MAPPING,
      {
        onCompleted: updateGameMappingCallback
      }
    );
  
  const verifyGameMappingCallback = useCallback((result: Developer_Verify_Game_MappingMutation) => {
    if (result.developer.verifyGameMapping) {
      setGameMapping(result.developer.verifyGameMapping);
      setUpdating(false);
    }
  }, [setGameMapping, setUpdating]);

  const [verifyGameMapping] =
    useMutation<Developer_Verify_Game_MappingMutation, Developer_Verify_Game_MappingMutationVariables>(
      DEVELOPER_VERIFY_GAME_MAPPING,
      {
        onCompleted: verifyGameMappingCallback
      }
    );

  const [refetchGameMapping] =
    useMutation<
      Developer_Refetch_Game_MappingMutation, 
      Developer_Refetch_Game_MappingMutationVariables
    >(
      DEVELOPER_REFETCH_GAME_MAPPING,
      {
        onCompleted: refetchGameMappingCallback
      }
    );

  const handleRefetchButtonClick = useCallback(() => {
    setUpdating(true);
    refetchGameMapping({
      variables: {
        input: {
          gameId: gameMapping.gameId
        }
      }
    });
  }, [gameMapping.gameId, refetchGameMapping]);

  const handleUpdateGameMappingClick = useCallback(() => {
    let igdbGameId = (document.getElementById(`${gameMapping.gameId}-igdb-id-input`) as any).value as number;
    setUpdating(true);
    updateGameMapping({
      variables: {
        input: {
          gameId: gameMapping.gameId,
          igdbGameId: igdbGameId
        }
      }
    });
  }, [gameMapping.gameId, setUpdating, updateGameMapping]);

  const handleVerifyGameMappingClick = useCallback(() => {
    setUpdating(true);
    verifyGameMapping({
      variables: {
        input: {
          gameId: gameMapping.gameId,
          igdbGameId: gameMapping.igdbGameId,
        }
      }
    });
  }, [gameMapping.gameId, gameMapping.igdbGameId, setUpdating, verifyGameMapping]);
  
  const igdbMappingString = useMemo(() => {
    return gameMapping.igdbGameId 
      ? `IGDB #${gameMapping.igdbGameId} (${gameMapping.igdbGameTitle})`
      : 'UNMAPPED';
  }, [gameMapping.igdbGameId, gameMapping.igdbGameTitle]);

  const icon = useMemo(() => getIconForPlatform(gameMapping.gameId), [gameMapping.gameId]);
  const scoreColor = useMemo(() => getColorForScore(gameMapping.igdbGameMappingScore), [gameMapping.igdbGameMappingScore]);

  return (
    <div style={props.style}>
      <div className={classes.tableRow}>
        <div className={`${classes.tableRowCell} ${classes.scoreWrapper}`}>
          <Typography variant="h3">
            <span style={{
              color: scoreColor, 
              fontWeight: 'bold'}
            }>
              {gameMapping.igdbGameMappingScore}
            </span>
          </Typography>
        </div>

        <div className={`${classes.tableRowCell} ${classes.button}`}>
          <Button
            onClick={handleVerifyGameMappingClick}
            disabled={gameMapping.igdbGameMappingIsVerified || updating}
            variant="contained" 
            color="primary"
            fullWidth
          >
            {
              gameMapping.igdbGameMappingIsVerified
                ? 'Verified'
                : 'Verify'
            }
          </Button>
        </div>

        <div className={`${classes.tableRowCell} ${classes.button}`}>
          <Button
            onClick={handleRefetchButtonClick}
            disabled={updating}
            variant="contained" 
            color="primary"
            fullWidth
          >
            Refetch
          </Button>
        </div>

        <div className={classes.tableRowCell}>
          <img src={icon} 
            title={gameMapping.gameId}
            alt='' 
            height={32}
          />
        </div>

        <div className={`${classes.tableRowCell} ${classes.gameMappingDescription}`}>
          <Typography variant="subtitle1">
            {gameMapping.gameTitle}
          </Typography>
          <Typography variant="body1">
            {igdbMappingString}
          </Typography>
        </div>

        <div className={`${classes.tableRowCell} ${classes.mappingInputs}`}>
          <TextField 
            id={`${gameMapping.gameId}-igdb-id-input`}
            label="New IGDB ID" 
            variant="outlined" 
          />
          <Button
            disabled={updating}
            variant="contained"
            onClick={handleUpdateGameMappingClick}
            color="primary"
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}

function getColorForScore(score: number): string {
  return score > 90 
    ? 'green' 
    : score > 70 
      ? 'yellow' 
      : 'red';
}

function getIconForPlatform(gameId: string): string {
  if (gameId.startsWith('Steam')) {
    return '/images/providers/steam_dark.png';
  } else if (gameId.startsWith('Gog')) {
    return '/images/providers/gog_dark.png';
  } else if (gameId.startsWith('PlayStation')) {
    return '/images/providers/playstation_dark.png';
  } else if (gameId.startsWith('Xbox')) {
    return '/images/providers/xbox_dark.png';
  } else {
    return '/images/providers/custom_dark.png';
  }
}