import { gql } from 'apollo-boost';

export const DEVELOPER_GAME_MAPPING_FRAGMENT = gql`
  fragment GameMappingFragment on GameMappingType {
    gameId
    gameTitle
    igdbGameId
    igdbGameMappingIsVerified
    igdbGameMappingScore
    igdbGameTitle
  }
`;