/**
 * Setup.tsx
 * 
 * This component is responsible for presenting the initial setup experience.
 */

import React, { useState } from 'react';
import { makeStyles, Button, Typography, Divider, Toolbar, AppBar, Card } from '@material-ui/core';
import ProviderSettings from '../../components/AccountSettings/ProviderSettings';
import { providerConfigMap } from '../../models/ProviderConfig';
import { useProfile } from '../../hooks/profile';
import { ProviderEnum } from '../../generated/graphql-types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    height: '100%',
    maxWidth: '600px',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      overflowY: 'scroll'
    },
    padding: theme.spacing(3)
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  sectionDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  welcomeHeading: {
    textAlign: 'center',
    width: '100%'
  }
}));

export default function Setup() {
  const classes = useStyles();

  const { profileData, setProfileData, completeSetup } = useProfile();

  const [isReady, setIsReady] = useState<boolean>(false);

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Setup
          </Typography>
        </Toolbar>
      </AppBar>
      
      <div className={classes.root}>
        <Card className={classes.card}>
          <Typography variant="h4" className={classes.welcomeHeading}>
            Welcome to AllMyGames!
          </Typography>

          <Divider className={classes.sectionDivider} />

          <Typography variant="body1">
            AllMyGames brings all of your games together in one place.
            Link your accounts to sync their games to your library.
          </Typography>

          <div className={classes.form}>
            <ProviderSettings
              config={providerConfigMap.get('gog')}
              onValueChange={(gogProfileName: string) => {
                profileData.gogProfileName = gogProfileName;
                setProfileData(profileData);
                setIsReady(true);
              }}
              providerId={ProviderEnum.Gog}
              value={profileData.gogProfileName} />
            <ProviderSettings
              config={providerConfigMap.get('psn')}
              onValueChange={(psnProfileName: string) => {
                profileData.psnProfileName = psnProfileName;
                setProfileData(profileData);
                setIsReady(true);
              }}
              providerId={ProviderEnum.PlayStation}
              value={profileData.psnProfileName} />
            <ProviderSettings
              config={providerConfigMap.get('steam')}
              onValueChange={(steamProfileName: string) => {
                profileData.steamProfileName = steamProfileName;
                setProfileData(profileData);
                setIsReady(true);
              }}
              providerId={ProviderEnum.Steam}
              value={profileData.steamProfileName} />
            <ProviderSettings
              config={providerConfigMap.get('xbox')}
              onValueChange={(xboxGamertag: string) => {
                profileData.xboxGamertag = xboxGamertag;
                setProfileData(profileData);
                setIsReady(true);
              }}
              providerId={ProviderEnum.Xbox}
              value={profileData.xboxGamertag} />
          </div>

          <Divider className={classes.sectionDivider} />

          <Button
            color="primary"
            disabled={!isReady}
            onClick={completeSetup} 
            variant={isReady ? 'contained' : 'outlined'}>
            {
              isReady ? "Let's go!" : 'Link at least one account'
            }
          </Button>
        </Card>

      </div>
    </>
  );
}