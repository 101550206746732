import React, { useState, useCallback, useRef, useEffect, ReactElement } from 'react';

import { useMyTheme } from '../../hooks/theme';
import { makeStyles, TextField, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { ProviderSyncStatus } from '../../models/ProviderSyncStatus';

import ProviderStatusIcon from './ProviderStatusIcon';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROVIDER_PROFILE_NAME_MUTATION } from '../../graphql/mutations';
import { ProviderVerificationStatus } from '../../models/ProviderVerificationStatus';
import ProviderHelpAlert from './ProviderHelpAlert';
import { ProviderConfig } from '../../models/ProviderConfig';
import { 
  Update_Provider_Profile_Name_MutationMutation, 
  Update_Provider_Profile_Name_MutationMutationVariables, 
  ProviderEnum 
} from '../../generated/graphql-types';
import { ExecutionResult } from 'apollo-boost';

export interface ProviderSettingsProps {
  config: ProviderConfig;
  onValueChange?: (updatedValue: string) => void;
  providerId: ProviderEnum;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    minWidth: '320px',
    marginTop: theme.spacing(3)
  },
  editButton: {
    margin: theme.spacing(1)
  },
  helpContainer: {
    maxWidth: '600px',
    padding: theme.spacing(1)
  },
  icon: {
    width: '24px',
    marginRight: theme.spacing(1),
    flexShrink: 0
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  inputTextField: {
    flexGrow: 1
  }
}));

export default function ProviderSettings(props: ProviderSettingsProps) {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState<string>(props.value);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [providerVerificationStatus, setProviderVerificationStatus] =
    useState<ProviderVerificationStatus>(ProviderVerificationStatus.Unknown);
  const [providerSyncStatus, setProviderSyncStatus] =
    useState<ProviderSyncStatus>(props.value ? ProviderSyncStatus.Synced : ProviderSyncStatus.Unset);
  const [updateProviderProfileName] = 
    useMutation<Update_Provider_Profile_Name_MutationMutation, Update_Provider_Profile_Name_MutationMutationVariables>(
      UPDATE_PROVIDER_PROFILE_NAME_MUTATION);

  const { isDarkMode } = useMyTheme();

  const handleProfileNameChange = useCallback((profileName: string) => {
    setProviderSyncStatus(ProviderSyncStatus.Syncing);
    setIsEditing(false);
    updateProviderProfileName({
      variables: {
        input: {
          profileName: profileName,
          provider: props.providerId
        }
      }
    }).then(
      (response: ExecutionResult<Update_Provider_Profile_Name_MutationMutation>) => {
        let responseVerificationStatus = parseProviderVerificationStatus(response.data.updateProviderProfileName.providerState);
        let updatedProviderState =
          responseVerificationStatus === ProviderVerificationStatus.Verified
            ? ProviderSyncStatus.Synced
            : ProviderSyncStatus.Error;
        setProviderSyncStatus(updatedProviderState);
        setProviderVerificationStatus(responseVerificationStatus);
        props.onValueChange(response.data.updateProviderProfileName.profileName);
      },
      (reason: any) => {
        setProviderSyncStatus(ProviderSyncStatus.Error);
      }
    );
  }, [props, updateProviderProfileName]);

  const icon = isDarkMode ? props.config.iconDark : props.config.iconLight; 

  const inputRef = useRef(null);

  const editingAdornment = getEditingAdornment(isEditing, setIsEditing, providerSyncStatus);
  
  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleChange = useCallback((event) => setInputValue(event.target.value), [setInputValue]);
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleProfileNameChange(inputValue);
      event.preventDefault();
    }
  }, [handleProfileNameChange, inputValue]);
  const handleSave = useCallback(() => handleProfileNameChange(inputValue), [handleProfileNameChange, inputValue]);

  const isTextFieldDisabled = !isEditing || providerSyncStatus === ProviderSyncStatus.Syncing;

  const textFieldInputProps = {
    startAdornment: <img src={icon} alt='' className={classes.icon} />,
    endAdornment: editingAdornment
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <TextField
          id={props.config.fieldId}
          label={props.config.fieldLabel}
          variant="outlined"
          className={classes.inputTextField}
          inputRef={inputRef}
          disabled={isTextFieldDisabled}
          InputProps={textFieldInputProps}
          value={inputValue ?? ''}
          onChange={handleChange}
          onKeyPress={handleKeyPress} />

        <ProviderStatusIcon
          isEditing={isEditing}
          isSaveEnabled={inputValue != null}
          onSave={handleSave}
          providerStatus={providerSyncStatus} />
      </div>

      <ProviderHelpAlert
        isVisible={providerSyncStatus === ProviderSyncStatus.Error}
        messages={props.config.helpMessages}
        status={providerVerificationStatus} />
    </div>
  );
}

function parseProviderVerificationStatus(status: string): ProviderVerificationStatus {
  switch (status) {
    case 'VERIFIED':
      return ProviderVerificationStatus.Verified;
    case 'VERIFICATION_FAILED':
      return ProviderVerificationStatus.Verified;
    case 'PRIVATE':
      return ProviderVerificationStatus.Private;
    case 'BLOCKED':
      return ProviderVerificationStatus.Blocked;
    case 'DOES_NOT_EXIST':
      return ProviderVerificationStatus.DoesNotExist;
    case 'UNKNOWN':
    default:
      return ProviderVerificationStatus.Unknown;
  }
}

function getEditingAdornment(
  isEditing: boolean, 
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  status: ProviderSyncStatus
): ReactElement {
  const cancelEditButton = (
    <IconButton title="Cancel" onClick={() => setIsEditing(false)}>
      <CloseIcon />
    </IconButton>
  );

  const editNameButton = (
    <IconButton title="Edit Name" onClick={() => setIsEditing(true) }>
      <EditIcon />
    </IconButton>
  );

  if (!isEditing) {
    return editNameButton;
  } else if (status !== ProviderSyncStatus.Syncing) {
    return cancelEditButton;
  } else {
    return null;
  }
}