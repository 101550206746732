import React,{ ReactElement } from 'react';

import { blue, green, red } from '@material-ui/core/colors';

import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { makeStyles, IconButton } from '@material-ui/core';
import { DisplayNameInputStatus } from './DisplayNameInput';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '48px'
  },
  errorIcon: {
    color: red[500]
  },
  loadingIcon: {
    color: blue[500]
  },
  okIcon: {
    color: green[500]
  }
}));

export interface DisplayNameInputStatusIconProps {
  isEditing: boolean;
  isSaveEnabled: boolean;
  onSave: () => void;
  status: DisplayNameInputStatus;
}

export default function DisplayNameInputStatusIcon(props: DisplayNameInputStatusIconProps) {
  const classes = useStyles();

  const isButtonDisabled = !(props.isEditing && props.isSaveEnabled);

  let button: ReactElement = null;
  if (props.isEditing) {
    button = (
      <IconButton 
        disabled={isButtonDisabled}
        onClick={props.onSave}
      >
        <SaveIcon />
      </IconButton>
    );
  } else {
    let icon: ReactElement;
    switch (props.status) {
      case DisplayNameInputStatus.Error:
        icon = <ErrorOutlineIcon className={classes.errorIcon} />;
        break;
      case DisplayNameInputStatus.Ok:
        icon = <CheckIcon className={classes.okIcon} />;
        break;
      case DisplayNameInputStatus.Loading:
        icon = <SyncIcon className={classes.loadingIcon} />;
        break;
      default:
        return null;
    }

    button = (
      <div className={classes.root}>
        <IconButton disabled={isButtonDisabled}>
          {icon}
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {button}
    </div>
  );
}