/* eslint-disable max-len */

import React, { ReactElement } from 'react';

import { ProviderVerificationStatus } from './ProviderVerificationStatus';

export interface ProviderConfig {
    fieldId: string;
    fieldLabel: string;
    helpMessages: Map<ProviderVerificationStatus, ReactElement>;
    iconLight: string;
    iconDark: string;
}

export const providerConfigMap: Map<string, ProviderConfig> = new Map<string, ProviderConfig>([
  ['gog', {
    fieldId: 'gogName',
    fieldLabel: 'GOG Profile Name',
    helpMessages: new Map<ProviderVerificationStatus, ReactElement>([
      [ProviderVerificationStatus.DoesNotExist, 
        <>
          Unable to find GOG account with this name.
          Please provide your GOG ID. 
          This is the username you use to log into the GOG client.
        </>],
      [ProviderVerificationStatus.Private, 
        <>
          This GOG account is private. 
          In order for us to access your GOG games, you'll need to make sure your game list is public.
          To configure the privacy settings for your GOG profile, 
          go to <a href='https://www.gog.com/account/settings/privacy'>https://www.gog.com/account/settings/privacy</a>.
          (You will need to sign in with your GOG account to access this page.)
          On this page, enable the <b>Profile Enabled</b> checkbox and set the 
          <b>Your full game library can be seen by</b> option to <b>all visitors</b>.";
        </>],
      [ProviderVerificationStatus.VerificationFailed, 
        <>
          Failed to verify this GOG account. Please try again later.
        </>]
    ]),
    iconLight: '/images/providers/gog_color.png',
    iconDark: '/images/providers/gog_white.png'
  }],
  ['steam', {
    fieldId: 'steamName',
    fieldLabel: 'Steam Community ID',
    helpMessages: new Map<ProviderVerificationStatus, ReactElement>([
      [ProviderVerificationStatus.DoesNotExist, 
        <>
          Unable to find Steam account with this Steam Community ID.
          Please provide your Steam Community ID.
          To find your Steam Community ID, go to <b><a href='https://www.steamcommunity.com/my/profile'>https://www.steamcommunity.com/my/profile</a></b>.
          (You will need to sign in with your Steam account to access this page.)
          Look at the URL in your browser's address bar. Your Steam Community ID is the word or long number that appears at the end of the address.
          For example: the address https://www.steamcommunity.com/id/<b>4245119842049</b> includes the Steam Community ID <b>4245119842049</b>.
        </>],
      [ProviderVerificationStatus.Private, 
        <>
          This Steam account is private. Please change your privacy settings to enable syncing.
          To configure the privacy settings for your Steam profile, go to <a href='https://www.steamcommunity.com/my/edit/settings'>https://www.steamcommunity.com/my/edit/settings</a>.
          (You will need to sign in with your Steam account to access this page.)
          On this page, ensure that the <b>My profile</b> and <b>Game details</b> settings are configured as <b>Public</b>.
        </>],
      [ProviderVerificationStatus.VerificationFailed, 
        <>
          Failed to verify this Steam account. Please try again later.
        </>]
    ]),
    iconLight: '/images/providers/steam_color.png',
    iconDark: '/images/providers/steam_white.png'
  }],
  ['psn', {
    fieldId: 'psnName',
    fieldLabel: 'PSN Profile Name',
    helpMessages: new Map<ProviderVerificationStatus, ReactElement>([
      [ProviderVerificationStatus.DoesNotExist, 
        <>
          Unable to find PlayStation Network account with this PSN Name.
          Please provide your PSN Name.
        </>],
      [ProviderVerificationStatus.Private, 
        <>
          This PlayStation Network account is private. Please change your privacy settings to enable syncing.
          Please ensure that your PlayStation privacy settings are configured to allow Anyone to see your trophies by following
          the instructions <a href='https://forum.psnprofiles.com/topic/12212-trophies-are-missing-hidden-or-private-trophies-how-to-fix-it/'>here</a>,
          then try again.
        </>],
      [ProviderVerificationStatus.VerificationFailed, 
        <>
          Failed to verify this PlayStation Network acount. Please try again later.
        </>]
    ]),
    iconLight: '/images/providers/playstation_color.png',
    iconDark: '/images/providers/playstation_white.png'
  }],
  ['xbox', {
    fieldId: 'xboxName',
    fieldLabel: 'Xbox Profile Name',
    helpMessages: new Map<ProviderVerificationStatus, ReactElement>([
      [ProviderVerificationStatus.DoesNotExist, 
        <>
          Unable to find Xbox Live account with this Gamertag.
          Please provide your Xbox Live Gamertag.
        </>],
      [ProviderVerificationStatus.Private, 
        <>
          This Xbox Live account is private. Please change your privacy settings to enable syncing.
          To configure the privacy settings for your Xbox profile, 
          go to <a href='https://account.xbox.com/Settings'>https://account.xbox.com/Settings</a> and
          set <b>See your game and app history (Xbox 360: Game History)</b> to <b>Everybody</b>.
        </>],
      [ProviderVerificationStatus.VerificationFailed, 
        <>
          Failed to verify this Xbox Live acount. Please try again later.
        </>]
    ]),
    iconLight: '/images/providers/xbox_color.png',
    iconDark: '/images/providers/xbox_white.png'
  }]
]);