import React, { useMemo } from 'react';
import { makeStyles, List, ListItem } from '@material-ui/core';
import UserFollow, { UserFollowProps } from './UserFollow';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  list: {
    width: '100%'
  }
}));

export interface UserFollowListProps {
  userFollows: UserFollowProps[];
}

export default function UserFollowList(props: UserFollowListProps) {
  const classes = useStyles();

  const listItems = useMemo(() => {
    return props.userFollows.map(userFollow => {
      return (
        <ListItem>
          <UserFollow {...userFollow} />
        </ListItem>
      );
    })
  }, [props.userFollows]);

  return (
    <div className={classes.root}>
      <List 
        component="nav"
        className={classes.list}>
        {listItems}
      </List>
    </div>
  );
}