/**
 * Wishlist.tsx
 * 
 * This component is responsible for displaying the Wishlist page.
 */

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { GameCardProps } from '../../components/GameCard';
import LibraryDrawer from '../../components/Home/LibraryDrawer';
import AutoSizer from 'react-virtualized-auto-sizer';
import GameGrid from '../../components/GameGrid';
import { WISHLIST_QUERY } from '../../graphql/queries';
import useQuery from '../../hooks/query-hook';
import { Wishlist_QueryQuery, Wishlist_QueryQueryVariables } from '../../generated/graphql-types';
import { ApolloQueryResult } from 'apollo-boost';
import SelectedGameModal from '../../components/SelectedGameModal';
import AddCustomGameModal from '../../components/AddCustomGamesModal';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    width: drawerWidth,
  },
  games: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  gamesHeader: {
    color: 'rgba(255,255,255,.5)',
    textAlign: 'center'
  },
  progress: {
    left: '50%',
    marginRight: '-50%',
    position: 'relative',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50px'
  }
}));

interface WishlistProps {
  drawerOpen: boolean,
  onDrawerClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void,
  searchQuery: string
}

export default function Wishlist(props: WishlistProps) {
  const classes = useStyles();

  const [favoritesFilterEnabled, setFavoritesFilterEnabled] = useState<boolean>(false);
  const [games, setGames] = useState<GameCardProps[]>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openCustomGameModal, setOpenCustomGameModal] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<GameCardProps>(null);
  const [sortedGames, setSortedGames] = useState<GameCardProps[]>(null);
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [sortType, setSortType] = useState<string>('name');

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedGame(null);
  }, [setSelectedGame, setOpen]);

  const handleOpen = useCallback((game: GameCardProps) => {
    setSelectedGame(game);
    setOpen(true);
  }, [setSelectedGame, setOpen]);

  const queryCallback = useCallback((result: ApolloQueryResult<Wishlist_QueryQuery>) => {
    if (result.data.me) {
      setGames(result.data.me.wishlist.map((entry: any) => {
        return {
          key: entry.game.id,
          id: entry.game.id,
          title: entry.game.title,
          image: entry.game.image,
          favoriteToggleEnabled: false,
          isFavorite: false,
          isHidden: false,
          isWishlist: true,
          provider: entry.provider,
          rating: 0,
          state: '',
          get onClick() {
            return () => handleOpen(this);
          },
          get toggleFavorite() {
            return null;
          },
          get updateRating() {
            return null;
          },
          ratingControlEnabled: false
        } as GameCardProps;
      }));
    }
  }, [handleOpen]);

  const { reload, loading } = useQuery<Wishlist_QueryQuery, Wishlist_QueryQueryVariables>(WISHLIST_QUERY, queryCallback);

  const handleCloseCustomGameModal = useCallback((refreshWishlist?: boolean) => {
    setOpenCustomGameModal(false);

    if (refreshWishlist) {
      reload();
    }
  }, [setOpenCustomGameModal, reload]);

  /**
   * Sort the entries in GameGrid whenever sortOrder or sortType change
   */
  useEffect(() => {
    if (!games) {
      return;
    }

    let sorted: GameCardProps[] = [...games];
    if (favoritesFilterEnabled) {
      sorted = sorted.filter(x => x.isFavorite);
    }

    switch (sortType) {
      case 'name':
      default:
        sorted.sort((x, y) => x.title.localeCompare(y.title));
        break;
      case 'rating':
        sorted.sort((x, y) => x.rating - y.rating);
    }

    if (sortOrder === 'desc') {
      sorted.reverse()
    }

    const lowerCaseSearchQuery = props.searchQuery.toLowerCase();
    sorted = sorted.filter(x => x.title.toLowerCase().includes(lowerCaseSearchQuery));

    setSortedGames(sorted);
  }, [games, favoritesFilterEnabled, setSortedGames, sortOrder, sortType, props.searchQuery]);

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading &&
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          }

          {!loading && sortedGames && sortedGames.length !== 0 &&
            <AutoSizer>
              {({ height, width }) =>
                <GameGrid games={sortedGames} height={height} width={width} />
              }
            </AutoSizer>
          }
        </div>
        <LibraryDrawer
          className={classes.drawer}
          loading={loading}
          onPressRefresh={reload}
          onPressAddCustom={() => setOpenCustomGameModal(true)}
          open={props.drawerOpen}
          onClose={props.onDrawerClose}
          gameStateFilter={'ALL'}
          sortOrder={sortOrder}
          sortType={sortType}
          handleGameStateFilterChange={() => { }}
          handleSortOrderChange={setSortOrder}
          handleSortTypeChange={setSortType}
          favoritesFilterEnabled={favoritesFilterEnabled}
          handleFavoritesFilterEnabledChange={setFavoritesFilterEnabled}
          hiddenFilterEnabled={true}
          handleHiddenFilterEnabledChange={() => { }}
          showFiltersAndSorts={true}
          showAddCustom={true}
        />
      </div>

      <SelectedGameModal
        disableFavoriteAndRating
        onClose={handleClose}
        open={open}
        onDelete={() => {
          setOpen(false);
          reload();
        }}
        selectedGame={selectedGame}
      />

      <AddCustomGameModal
        open={openCustomGameModal}
        onClose={handleCloseCustomGameModal}
        isWishlist={true}
      />
    </Fragment>
  );
};