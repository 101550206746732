import React from 'react';
import { Typography, Button, makeStyles, createStyles, Container, Grid, Toolbar, Link } from '@material-ui/core';
import { useAuth } from '../../hooks/auth';
import { Redirect } from 'react-router-dom';
import FeatureGrid from './FeatureGrid';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center'
    },
    banner: {
      background: `url(/images/banner_tile.png), 
        -webkit-linear-gradient(
          top, 
          #353b41 0%, 
          rgba(53, 59, 65, 0.96) 19%, 
          rgba(53, 59, 65, 0.91) 34%, 
          rgba(53, 59, 65, 0.85) 47%, 
          rgba(53, 59, 65, 0.79) 56.5%, 
          rgba(53, 59, 65, 0.73) 65%, 
          rgba(53, 59, 65, 0.65) 73%, 
          rgba(53, 59, 65, 0.55) 80.2%, 
          rgba(53, 59, 65, 0.45) 86.1%, 
          rgba(53, 59, 65, 0.33) 91%, 
          rgba(53, 59, 65, 0.2) 95.2%, 
          rgba(53, 59, 65, 0.08) 98.2%, 
          rgba(53, 59, 65, 0.04) 100%
        )`,
      backgroundColor: 'white',
      display: 'flex',
      height: '600px',
      flexDirection: 'column',
      justifyContent: 'center',
      maxHeight: 'calc(100vh - 56px)'
    },
    bannerText: {
      color: 'white',
      marginBottom: theme.spacing(2)
    },
    buttonGrid: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    bottomRegisterButton: {
      marginTop: theme.spacing(3)
    },
    card: {
      maxWidth: '600px',
      padding: theme.spacing(1)
    },
    contentContainer: {
      backgroundColor: 'white',
      '&:nth-child(even)': {
        backgroundColor: '#f5f5f5'
      },
      color: 'black'
    },
    footerItem: {
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center'
    },
    section: {
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(4)
    },
    sectionHeading: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    paragraph: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
);

function LandingPage() {
  const classes = useStyles();

  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/library" />; 
  }

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar>
          <img src='/images/logo_pixel.svg' alt='' height={32}></img>
        </Toolbar>
      </Container>
      <Container className={classes.banner} maxWidth="xl">
        <Container maxWidth="lg">
          <Typography variant="h3" className={classes.bannerText}>
            Unify your game collections, backlogs, and wishlists
          </Typography>
          <Typography variant="subtitle2" className={classes.bannerText}>
            Sync your games from Steam, PlayStation, Xbox, GOG, and more.
          </Typography>
          <Grid 
            className={classes.buttonGrid}
            container 
            spacing={1}>
            <Grid item>
              <Button 
                component={RouterLink}
                to="/library"
                variant="contained" 
                color="primary">
                Sign Up
              </Button>
            </Grid>
            <Grid item>
              <Button 
                component={RouterLink}
                to="/library"
                variant="contained" 
                color="primary">
                Login
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container 
        className={classes.contentContainer}
        maxWidth="xl">
        <Container maxWidth="sm" className={classes.section}>
          <Typography 
            className={classes.sectionHeading}
            variant="h4" 
            align="center">
            What we do
          </Typography>
          <div className={classes.paragraph}>
            <Typography variant="body1">
              Do you have more games than you know what to do with? 
              With hundreds of games scattered across countless platforms and launchers, 
              it's hard to remember what we even own, much less decide what to play next. 
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="body1">
              AllMyGames makes it easy to keep track of your games and beat your backlog by bringing everything together in one place. 
            </Typography>
          </div>
        </Container>
      </Container>
      <Container 
        className={classes.contentContainer}
        maxWidth="xl">
        <Container maxWidth="md" className={classes.section}>
          <Typography 
            className={classes.sectionHeading}
            variant="h4" 
            align="center">
            How it works
          </Typography>
          <FeatureGrid />
        </Container>
      </Container>
      <Container 
        className={classes.contentContainer}
        maxWidth="xl">
        <Container maxWidth="sm" className={classes.section}>
          <Typography 
            className={classes.sectionHeading}
            variant="h4" 
            align="center">
            Join our community of gamers
          </Typography>
          <div style={{textAlign: 'center'}}>
            <iframe 
              src="https://discordapp.com/widget?id=472204996709187594&theme=dark" 
              width="256" 
              height="400" 
              allowTransparency={true}
              frameBorder="0" 
              title="discord-widget">
            </iframe>
          </div>
        </Container>
      </Container>
      <Container
        className={classes.contentContainer}
        maxWidth="xl">
        <Container maxWidth="sm" className={classes.section}>
          <Typography 
            className={classes.sectionHeading}
            variant="h4" 
            align="center">
            Sign up today
          </Typography>
          <div className={classes.paragraph}>
            <Typography variant="body1" align="center">
              Setup is easy! Get all your games in one place today so you can 
              spend less time searching and more time playing.
            </Typography>
            <Button 
              className={classes.bottomRegisterButton}
              component={RouterLink}
              to="/library"
              variant="contained" 
              color="primary"
              fullWidth={true}>
              Join AllMyGames
            </Button>
          </div>
        </Container>
      </Container>
      <Toolbar>
        <Grid container spacing={3}>
          <Grid item xs={4} className={classes.footerItem}>
            <Typography align="center">
              © 2020 - Condorcet 
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.footerItem}>
            <Link 
              component="a" 
              href="https://blog.allmy.games/"
              color="textPrimary">
              <Typography align="center">
                Blog
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4} className={classes.footerItem}>
            <Link 
              component={RouterLink} 
              to="/privacy"
              color="textPrimary">
              <Typography align="center">
                Privacy Policy
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </>
  );
}

export default LandingPage;