import React, { useCallback } from 'react';

import { createStyles, makeStyles, Theme, fade, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export interface SearchBarProps {
  setSearchQuery: (string) => void;
  enterPressedHandler?: () => void;
  //determines whether the search bar will always be the same size, or animate to expand on focus
  fixedSize?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputFocused: {
      width: (props: SearchBarProps) => props.fixedSize ? '100%' : '20ch',
      [theme.breakpoints.up('sm')]: {
        width: (props: SearchBarProps) => props.fixedSize ? '100%' : '30ch'
      }
    },
    inputInput: {
      padding: theme.spacing(0.5, 0.5, 0.5, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: (props: SearchBarProps) => props.fixedSize ? '100%' : '0ch',
      [theme.breakpoints.up('sm')]: {
        width: (props: SearchBarProps) => props.fixedSize ? '100%' : '20ch',
      }
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    search: {
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    searchIcon: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      pointerEvents: 'none',
      position: 'absolute',
    }
  })
);
  

export default function SearchBar(props: SearchBarProps) {
  const classes = useStyles(props);
  
  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.setSearchQuery(event.target.value);
  }, [props]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (props.enterPressedHandler) {
        props.enterPressedHandler();
      }
    }
  }, [props]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <div className={classes.inputRoot}>
        <Input
          placeholder="Search…"
          disableUnderline={true}
          classes={{
            root: classes.inputInput,
            focused: classes.inputFocused
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>

    </div>
  );
}