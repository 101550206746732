import { createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { useProfile } from '../../hooks/profile';
import IgdbGameMappingTool from './IgdbGameMappingTool';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 501,
    },
    appBarContentLeft: {
      flexGrow: 1, 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center'
    },
    content: {
      flexGrow: 1,
      maxHeight: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    libraryDrawerButton: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxHeight: '100%',
      width: '100%'
    }
  })
);

export interface DeveloperToolsPageProps {
  toggleAppDrawer: () => void;
}

export default function DeveloperToolsPage(props: DeveloperToolsPageProps) {
  const classes = useStyles();
  
  const { profileData } = useProfile();

  if (!profileData.isDeveloper) {
    return <Redirect to='/'/>;
  }

  return (
    <div className={classes.root}>
      <Toolbar className={classes.appBar}>
        <div className={classes.appBarContentLeft}>
          <IconButton edge="start" className={classes.menuButton}
            color="inherit" aria-label="menu"
            onClick={props.toggleAppDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            Developer Tools  
          </Typography>
        </div>
      </Toolbar>
      <div className={classes.content}>
        <IgdbGameMappingTool />
      </div>
    </div>
  );
};