import React, { useCallback, useState } from 'react';
import {
  Dialog, Card, CardMedia, CardContent, Typography, makeStyles, Box, Select, InputLabel, FormControl, MenuItem, FormControlLabel, Switch,
  Grid, Button,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import DeleteIcon from '@material-ui/icons/Delete';
import { GameCardProps } from './GameCard';
import { ProviderLogo } from './ProviderLogo';
import { FavoriteControl } from './FavoriteControl';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_LIBRARY_ENTRY_MUTATION, REMOVE_CUSTOM_GAME_MUTATION } from '../graphql/mutations';
import {
  LibraryGameState, Update_Library_Entry_MutationMutation, Update_Library_Entry_MutationMutationVariables, ProviderEnum, 
  Remove_Custom_Game_MutationMutation, Remove_Custom_Game_MutationMutationVariables
} from '../generated/graphql-types';
import FlexGrowBuffer from './FlexGrowBuffer';

const useStyles = makeStyles((theme) => ({
  blurBackground: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      display: 'block',
      height: '180px',
      width: '100%',
      position: 'absolute',
      filter: 'blur(12px)',
      top: 0
    }
  },
  cardRoot: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 400
    },
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  deleteButton: {
    width: '10rem'
  },
  dialog: {
    maxWidth: 'none'
  },
  dropdown: {
    minWidth: '120px'
  },
  interpunct: {
    marginBottom: '0',
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
  },
  media: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '180px',
      objectFit: 'contain',
      zIndex: 1
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
      width: '40%'
    },
  },
  ratingFavoriteContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface SelectedGameModalProps {
  disableFavoriteAndRating?: boolean;
  selectedGame: GameCardProps;
  onClose: () => void;
  onDelete: () => void;
  onGameStateChange?: (state: LibraryGameState) => void;
  onGameHiddenChange?: (isHidden: boolean) => void;
  open: boolean;
}

export default function SelectedGameModal(props: SelectedGameModalProps) {
  const classes = useStyles();
  const { selectedGame, open, onClose } = props;

  const [deleting, setDeleting] = useState<boolean>(false);

  const [removeCustomGame] =
    useMutation<Remove_Custom_Game_MutationMutation, Remove_Custom_Game_MutationMutationVariables>(REMOVE_CUSTOM_GAME_MUTATION);
  const [updateLibraryEntry] =
    useMutation<Update_Library_Entry_MutationMutation, Update_Library_Entry_MutationMutationVariables>(UPDATE_LIBRARY_ENTRY_MUTATION);

  const handleDeleteButtonClick = useCallback(async () => {
    setDeleting(true);
    let result = await removeCustomGame({
      variables: {
        input: {
          gameId: selectedGame.id,
          isWishlist: selectedGame.isWishlist
        }
      }
    });

    if (result.data.removeCustomGame.succeeded) {
      props.onDelete();
    }

    setDeleting(false);
  }, [props, removeCustomGame, selectedGame]);

  const handleGameStateChange = useCallback((state: LibraryGameState) => {
    if (selectedGame?.state !== state) {
      selectedGame.state = state;
      if (state !== LibraryGameState.Backlog) {
        selectedGame.backlogPosition = 0;
      }

      updateLibraryEntry({
        variables: {
          gameId: selectedGame.id,
          state: state
        }
      });

      if (props.onGameStateChange) {
        props.onGameStateChange(state);
      }
    }
  }, [props, selectedGame, updateLibraryEntry]);

  const handleHiddenChange = useCallback((isHidden: boolean) => {
    if (selectedGame?.isHidden !== isHidden) {
      selectedGame.isHidden = isHidden;
      updateLibraryEntry({
        variables: {
          gameId: selectedGame.id,
          isHidden: isHidden
        }
      });

      if (props.onGameHiddenChange) {
        props.onGameHiddenChange(isHidden);
      }
    }
  }, [props, selectedGame, updateLibraryEntry]);

  const deleteGameButton =
    selectedGame && props.selectedGame?.provider === ProviderEnum.Custom
      ? (
        <Button
          className={classes.deleteButton}
          disabled={deleting}
          onClick={handleDeleteButtonClick}
          startIcon={<DeleteIcon />}
          variant="outlined">
        Delete game
        </Button>
      )
      : null;

  const favoriteAndRatingControls = 
    !selectedGame || props.disableFavoriteAndRating
      ? null
      : (
        <Grid item>
          <Box className={classes.ratingFavoriteContainer}>
            <FavoriteControl
              isFavorite={selectedGame.isFavorite}
              toggleFavorite={selectedGame.toggleFavorite}
            />

            <Typography
              variant={'h5'}
              gutterBottom
              className={classes.interpunct}
            >
        &middot;
            </Typography>

            <Rating 
              name={`game-card-modal-rating-${selectedGame.id}`}
              size="large" 
              value={selectedGame.rating} 
              onChange={selectedGame.updateRating} />
          </Box>
        </Grid>
      );
  

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} fullWidth={true} maxWidth={'md'}>
      {selectedGame && (
        <Card className={classes.cardRoot}>
          <div
            className={classes.blurBackground}
            style={{
              backgroundImage: `url(${selectedGame.image})`
            }} />
          <CardMedia
            className={classes.media}
            component="img"
            image={selectedGame.image}
          />

          <CardContent className={classes.content}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Typography
                    variant={'h5'}
                  >
                    {selectedGame.title}
                  </Typography>

                  <FlexGrowBuffer />

                  <ProviderLogo
                    provider={selectedGame.provider}
                    size='medium'
                  />
                </div>
              </Grid>

              { favoriteAndRatingControls }

              <Grid item>
                <FormControl>
                  <InputLabel id="library-game-state-select-label">Game state</InputLabel>
                  <Select
                    className={classes.dropdown}
                    labelId="library-game-state-select-label"
                    id="library-game-state-select"
                    onChange={({ target }) => handleGameStateChange(target.value as (LibraryGameState))}
                    value={selectedGame.state}
                  >
                    {
                      Object.values(LibraryGameState).map(state =>
                        <MenuItem key={state} value={state}>
                          {getDisplayNameForLibraryGameState(state)}
                        </MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={props.selectedGame?.isHidden ?? false}
                      onChange={(event) => handleHiddenChange(event.target.checked)}
                      name="hiddenFilterSwitch"
                      color="primary"
                    />
                  }
                  label="Hide in library"
                />
              </Grid>
            </Grid>

            <FlexGrowBuffer />

            { deleteGameButton }

          </CardContent>
        </Card>
      )}
    </Dialog>
  );
}

function getDisplayNameForLibraryGameState(state: LibraryGameState): string {
  switch (state) {
    case LibraryGameState.Unplayed:
      return 'Unplayed';
    case LibraryGameState.Backlog:
      return 'Backlog';
    case LibraryGameState.Playing:
      return 'Playing';
    case LibraryGameState.Completed:
      return 'Completed';
    case LibraryGameState.WontPlay:
      return "Won't Play";
    case LibraryGameState.Paused:
      return 'Paused';
    case LibraryGameState.Abandoned:
      return 'Abandoned';
    default:
      return LibraryGameState[state] || '';
  }
}