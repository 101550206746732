/**
 * theme.tsx
 * 
 * This files defines a set of custom React hooks that enable us to access and mutate
 * the Material UI theme.
 */

import React, { useState, useContext, useCallback } from 'react';
import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

const commonThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1140,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: '#E50F14'
    },
  }
};

const lightThemeOptions: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    ...commonThemeOptions.palette,
    background: {
      default: '#f8f8f8'
    },
    primary: {
      main: '#E50F14'
    },
    type: 'light'
  }
};

const darkThemeOptions: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    ...commonThemeOptions.palette,
    primary: {
      main: '#A60C0E'
    },
    type: 'dark'
  }
}

export const MyThemeContext = React.createContext(undefined);
export const useMyTheme = () => useContext(MyThemeContext);
export const MyThemeProvider = ({
  children
}) => {
  const [themeConfig, setThemeConfig] = useState<ThemeOptions>(darkThemeOptions);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);

  const toggleTheme = useCallback(() => {
    setThemeConfig(isDarkMode ? lightThemeOptions : darkThemeOptions);
    setIsDarkMode(!isDarkMode);
  }, [isDarkMode, setIsDarkMode]);

  const theme = createMuiTheme(themeConfig);

  return (
    <MyThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MyThemeContext.Provider>
  );
};