import React from 'react';
import { makeStyles } from '@material-ui/core';
import { 
  AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifyForgotPassword 
} from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%'
  },
  authenticator: {
    margin: 'auto',
  }
}));

function PasswordMigrationAlert() {
  return (
    <Alert severity="warning">
      <b>Old password not working?</b>
      <br />
        We've upgraded our account system, requiring a password reset. 
        Click 'Reset password' below to regain access to your account.
    </Alert>
  );
}

export default function Login() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PasswordMigrationAlert />

      <AmplifyAuthenticator 
        className={classes.authenticator}
        initialAuthState={AuthState.SignIn}
      >
        <AmplifySignIn 
          usernameAlias="email"
          headerText="Sign in to AllMyGames"
          title="Title"
          slot="sign-in" 
        />
        <AmplifySignUp
          usernameAlias="email"
          formFields={
            [
              {
                type: 'email',
                label: 'Email Address',
                placeholder: 'Enter your email address',
                required: true,
              },
              {
                type: 'password',
                label: 'Password',
                placeholder: 'Choose a password',
                required: true,
              },
            ]
          }
          headerText="Sign up for AllMyGames"
          slot="sign-up"
        />
        <AmplifyForgotPassword
          slot="forgot-password"
          usernameAlias="email"
        />
      </AmplifyAuthenticator>
    </div>
  );
}