import React, { ReactElement, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { ProviderVerificationStatus } from '../../models/ProviderVerificationStatus';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export interface ProviderHelpAlertProps {
  isVisible: boolean;
  messages: Map<ProviderVerificationStatus, ReactElement>;
  status: ProviderVerificationStatus;
}

export default function ProviderHelpAlert(props: ProviderHelpAlertProps) {
  const classes = useStyles();
  const message = useMemo(() => props.messages.get(props.status), [props.messages, props.status]);

  if (!props.isVisible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Alert severity="error" className={classes.alert}>
        {message}
      </Alert>
    </div>
  );
}