import React, { useRef, useState, useCallback, ReactElement } from 'react';
import { TextField, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Update_Display_Name_MutationMutation, Update_Display_Name_MutationMutationVariables } from '../../generated/graphql-types';
import { UPDATE_DISPLAY_NAME_MUTATION } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'apollo-boost';
import Alert from '@material-ui/lab/Alert';
import DisplayNameInputStatusIcon from './DisplayNameInputStatusIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    minWidth: '320px',
    marginTop: theme.spacing(3)
  },
  alert: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  editButton: {
    margin: theme.spacing(1)
  },
  helpContainer: {
    maxWidth: '600px',
    padding: theme.spacing(1)
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  inputTextField: {
    flexGrow: 1
  }
}));

export enum DisplayNameInputStatus {
  None,
  Ok,
  Loading,
  Error
}

export interface DisplayNameInputProps {
  initialValue: string;
  onValueChange: (value: string) => void;
}

export default function DisplayNameInput(props: DisplayNameInputProps) {
  const classes = useStyles();
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState<string>(props.initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [status, setStatus] = 
    useState<DisplayNameInputStatus>(props.initialValue ? DisplayNameInputStatus.Ok : DisplayNameInputStatus.None)

  const [updateDisplayName] = 
    useMutation<Update_Display_Name_MutationMutation, Update_Display_Name_MutationMutationVariables>(UPDATE_DISPLAY_NAME_MUTATION);

  const handleChange = useCallback((event) => setInputValue(event.target.value), []);
  const handleValueChange = useCallback((value: string) => {
    setStatus(DisplayNameInputStatus.Loading);
    setIsEditing(false);
    updateDisplayName({
      variables: {
        input: {
          displayName: value
        }
      }
    }).then(
      (result: ExecutionResult<Update_Display_Name_MutationMutation>) => {
        let updateSucceeded = result.data.updateDisplayName.updateSucceeded;
        if (updateSucceeded) {
          setStatus(DisplayNameInputStatus.Ok);
          props.onValueChange(result.data.updateDisplayName.displayName);
        }
        else {
          setStatus(DisplayNameInputStatus.Error);
        }
      },
      () => {
        setStatus(DisplayNameInputStatus.Error);
      }
    );
  }, [props, setStatus, updateDisplayName]);
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleValueChange(inputValue);
      event.preventDefault();
    }
  }, [handleValueChange, inputValue]);
  const handleSave = useCallback(() => {
    handleValueChange(inputValue);
  }, [handleValueChange, inputValue]);

  const alert =
    status === DisplayNameInputStatus.Error
      ? (
        <Alert severity="error" className={classes.alert}>
          Failed to set Display Name. This one may be taken.
        </Alert>
      )
      : null;

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <TextField
          label="Display Name"
          variant="outlined"
          className={classes.inputTextField}
          inputRef={inputRef}
          disabled={!isEditing}
          InputProps={{
            endAdornment: getEditingAdornment(isEditing, setIsEditing, status)
          }}
          value={inputValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress} />

        <DisplayNameInputStatusIcon
          isEditing={isEditing}
          isSaveEnabled={inputValue != null}
          onSave={handleSave}
          status={status} />
      </div>

      {alert}
    </div>
  );
}

function getEditingAdornment(
  isEditing: boolean, 
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  status: DisplayNameInputStatus
): ReactElement {
  const cancelEditButton = (
    <IconButton title="Cancel" onClick={() => setIsEditing(false)}>
      <CloseIcon />
    </IconButton>
  );

  const editNameButton = (
    <IconButton title="Edit Name" onClick={() => {
      setIsEditing(true);
    }}>
      <EditIcon />
    </IconButton>
  );

  if (!isEditing) {
    return editNameButton;
  } else if (status !== DisplayNameInputStatus.Loading) {
    return cancelEditButton;
  } else {
    return null;
  }
}