export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AllMyGamesQuery = {
  __typename?: 'AllMyGamesQuery';
  developer: DeveloperQuery;
  feed?: Maybe<SocialFeedType>;
  me?: Maybe<User>;
  searchGame?: Maybe<SearchGameQueryResponseType>;
  user?: Maybe<User>;
};


export type AllMyGamesQuerySearchGameArgs = {
  input: SearchGameQueryInputType;
};


export type AllMyGamesQueryUserArgs = {
  input: UserQueryInputType;
};

export type DeveloperQuery = {
  __typename?: 'DeveloperQuery';
  gameMappings?: Maybe<GameMappingsResponseType>;
};


export type DeveloperQueryGameMappingsArgs = {
  input: GameMappingsInputType;
};

export type GameMappingsResponseType = {
  __typename?: 'GameMappingsResponseType';
  nextOffset: Scalars['Int'];
  /** Game Mapping results */
  results?: Maybe<Array<Maybe<GameMappingType>>>;
};

export type GameMappingType = {
  __typename?: 'GameMappingType';
  gameId: Scalars['String'];
  gameTitle: Scalars['String'];
  igdbGameId?: Maybe<Scalars['Int']>;
  igdbGameMappingIsVerified: Scalars['Boolean'];
  igdbGameMappingScore?: Maybe<Scalars['Int']>;
  igdbGameTitle?: Maybe<Scalars['String']>;
};

export type GameMappingsInputType = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

/** AllMyGames user. */
export type User = {
  __typename?: 'User';
  /** The collection of game backlog entries belonging to the user. */
  backlog?: Maybe<Array<Maybe<BacklogEntryType>>>;
  /** Collection stats for user. */
  collectionStats?: Maybe<CollectionStatsType>;
  /** The display name of the AllMyGames user. */
  displayName: Scalars['String'];
  /** The collection of the user's favorite games. */
  favorites?: Maybe<Array<Maybe<LibraryEntryType>>>;
  /** The other users that follow this user. */
  followers?: Maybe<Array<Maybe<User>>>;
  /** The other users that this user follows. */
  following?: Maybe<Array<Maybe<User>>>;
  /** The user's GOG profile name. */
  gogProfileName?: Maybe<Scalars['String']>;
  /** Whether the user has Developer permissions. */
  isDeveloper: Scalars['Boolean'];
  /** Whether the user's profile is private. */
  isProfilePrivate: Scalars['Boolean'];
  /** Whether the visitor making the request follows this user. */
  isVisitorFollowing?: Maybe<Scalars['Boolean']>;
  /** The collection of game library entries belonging to the user. */
  library?: Maybe<Array<Maybe<LibraryEntryType>>>;
  /** The number of followers this user has. */
  numFollowers: Scalars['Int'];
  /** The number of user that this user is following. */
  numFollowing: Scalars['Int'];
  /** The user's PSN profile name. */
  psnProfileName?: Maybe<Scalars['String']>;
  /** The user's Steam profile name. */
  steamProfileName?: Maybe<Scalars['String']>;
  /** The collection of game wishlist entries belonging to the user. */
  wishlist?: Maybe<Array<Maybe<WishlistEntryType>>>;
  /** The user's Xbox Gamertag. */
  xboxGamertag?: Maybe<Scalars['String']>;
};

export type BacklogEntryType = {
  __typename?: 'BacklogEntryType';
  backlogPosition: Scalars['Int'];
  game?: Maybe<GameType>;
  provider?: Maybe<LibraryGameProvider>;
  rating: Scalars['Int'];
  state?: Maybe<LibraryGameState>;
};

export type GameType = {
  __typename?: 'GameType';
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  provider?: Maybe<LibraryGameProvider>;
  title: Scalars['String'];
};

export enum LibraryGameProvider {
  Custom = 'CUSTOM',
  Gog = 'GOG',
  Steam = 'STEAM',
  PlayStation = 'PLAY_STATION',
  Xbox = 'XBOX'
}

export enum LibraryGameState {
  Unplayed = 'UNPLAYED',
  Backlog = 'BACKLOG',
  Playing = 'PLAYING',
  Completed = 'COMPLETED',
  WontPlay = 'WONT_PLAY',
  Paused = 'PAUSED',
  Abandoned = 'ABANDONED'
}

export type CollectionStatsType = {
  __typename?: 'CollectionStatsType';
  /** The number of games marked as Abandoned in the user's collection. */
  abandonedCount?: Maybe<Scalars['Int']>;
  /** The number of games in the user's backlog. */
  backlogCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Completed in the user's collection. */
  completedCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Favorite in the user's collection. */
  favoriteCount?: Maybe<Scalars['Int']>;
  /** The number of games in the user's library. */
  libraryCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Paused in the user's collection. */
  pausedCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Playing in the user's collection. */
  playingCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Unplayed in the user's collection. */
  unplayedCount?: Maybe<Scalars['Int']>;
  /** The number of games marked as Favorite in the user's collection. */
  wishlistCount?: Maybe<Scalars['Int']>;
};

export type LibraryEntryType = {
  __typename?: 'LibraryEntryType';
  game?: Maybe<GameType>;
  isFavorite: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  provider?: Maybe<LibraryGameProvider>;
  rating: Scalars['Int'];
  state?: Maybe<LibraryGameState>;
};

export type WishlistEntryType = {
  __typename?: 'WishlistEntryType';
  game?: Maybe<GameType>;
  provider?: Maybe<LibraryGameProvider>;
  rating: Scalars['Int'];
  state?: Maybe<LibraryGameState>;
};

export type SocialFeedType = {
  __typename?: 'SocialFeedType';
  items?: Maybe<Array<Maybe<SocialFeedItemType>>>;
};

export type SocialFeedItemType = {
  __typename?: 'SocialFeedItemType';
  displayName: Scalars['String'];
  eventAgeString: Scalars['String'];
  text: Scalars['String'];
};

export type UserQueryInputType = {
  displayName: Scalars['String'];
};

export type SearchGameQueryResponseType = {
  __typename?: 'SearchGameQueryResponseType';
  /** Result of the game search operation */
  games?: Maybe<Array<Maybe<GameSearchResultType>>>;
};

export type GameSearchResultType = {
  __typename?: 'GameSearchResultType';
  igdbId: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SearchGameQueryInputType = {
  searchTerm: Scalars['String'];
};

export type AllMyGamesMutation = {
  __typename?: 'AllMyGamesMutation';
  addCustomGames?: Maybe<AddCustomGamesResponseType>;
  developer: DeveloperMutation;
  removeCustomGame?: Maybe<RemoveCustomGameResponseType>;
  syncProviders?: Maybe<SyncProvidersResponseType>;
  updateBacklogEntry?: Maybe<BacklogEntryType>;
  updateDisplayName?: Maybe<UpdateDisplayNameResponseType>;
  updateFollowing?: Maybe<User>;
  updateLibraryEntry?: Maybe<LibraryEntryType>;
  updateProfilePrivacy?: Maybe<User>;
  updateProviderProfileName?: Maybe<ProviderUpdateResponseType>;
};


export type AllMyGamesMutationAddCustomGamesArgs = {
  input?: Maybe<AddCustomGamesInputType>;
};


export type AllMyGamesMutationRemoveCustomGameArgs = {
  input: RemoveCustomGameInputType;
};


export type AllMyGamesMutationUpdateBacklogEntryArgs = {
  gameId: Scalars['String'];
  backlogPosition: Scalars['Int'];
};


export type AllMyGamesMutationUpdateDisplayNameArgs = {
  input: UpdateDisplayNameInputType;
};


export type AllMyGamesMutationUpdateFollowingArgs = {
  input: UpdateFollowingInputType;
};


export type AllMyGamesMutationUpdateLibraryEntryArgs = {
  gameId: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['Int']>;
  state?: Maybe<LibraryGameState>;
};


export type AllMyGamesMutationUpdateProfilePrivacyArgs = {
  input: UpdateProfilePrivacyInputType;
};


export type AllMyGamesMutationUpdateProviderProfileNameArgs = {
  input: UpdateProviderProfileNameInputType;
};

export type DeveloperMutation = {
  __typename?: 'DeveloperMutation';
  computeMappingScores?: Maybe<Array<Maybe<GameMappingType>>>;
  refetchGameMapping?: Maybe<GameMappingType>;
  updateGameMapping?: Maybe<GameMappingType>;
  updateGameMappings?: Maybe<Array<Maybe<GameMappingType>>>;
  verifyGameMapping?: Maybe<GameMappingType>;
};


export type DeveloperMutationRefetchGameMappingArgs = {
  input: RefetchGameMappingInputType;
};


export type DeveloperMutationUpdateGameMappingArgs = {
  input: UpdateGameMappingInputType;
};


export type DeveloperMutationUpdateGameMappingsArgs = {
  input: UpdateGameMappingsInputType;
};


export type DeveloperMutationVerifyGameMappingArgs = {
  input: VerifyGameMappingInputType;
};

export type RefetchGameMappingInputType = {
  gameId: Scalars['String'];
};

export type UpdateGameMappingInputType = {
  gameId: Scalars['String'];
  igdbGameId: Scalars['Int'];
};

export type UpdateGameMappingsInputType = {
  maxUpdates: Scalars['Int'];
  scoreThreshold: Scalars['Int'];
};

export type VerifyGameMappingInputType = {
  gameId: Scalars['String'];
  igdbGameId: Scalars['Int'];
};

export type AddCustomGamesResponseType = {
  __typename?: 'AddCustomGamesResponseType';
  gameIds: Array<Scalars['Int']>;
  isWishlist: Scalars['Boolean'];
  succeeded: Scalars['Boolean'];
};

export type AddCustomGamesInputType = {
  gameIds: Array<Scalars['Int']>;
  isWishlist: Scalars['Boolean'];
};

export type RemoveCustomGameResponseType = {
  __typename?: 'RemoveCustomGameResponseType';
  succeeded: Scalars['Boolean'];
};

export type RemoveCustomGameInputType = {
  gameId: Scalars['String'];
  isWishlist: Scalars['Boolean'];
};

export type SyncProvidersResponseType = {
  __typename?: 'SyncProvidersResponseType';
  succeeded: Scalars['Boolean'];
};

export type UpdateDisplayNameResponseType = {
  __typename?: 'UpdateDisplayNameResponseType';
  displayName: Scalars['String'];
  updateSucceeded: Scalars['Boolean'];
};

export type UpdateDisplayNameInputType = {
  displayName: Scalars['String'];
};

export type UpdateFollowingInputType = {
  makeFollow: Scalars['Boolean'];
  targetDisplayName: Scalars['String'];
};

export type UpdateProfilePrivacyInputType = {
  makeProfilePrivate: Scalars['Boolean'];
};

export type ProviderUpdateResponseType = {
  __typename?: 'ProviderUpdateResponseType';
  profileName: Scalars['String'];
  providerState?: Maybe<ProviderState>;
};

export enum ProviderState {
  Unknown = 'UNKNOWN',
  Verified = 'VERIFIED',
  DoesNotExist = 'DOES_NOT_EXIST',
  Private = 'PRIVATE',
  VerificationFailed = 'VERIFICATION_FAILED',
  Blocked = 'BLOCKED'
}

export type UpdateProviderProfileNameInputType = {
  profileName: Scalars['String'];
  provider?: Maybe<ProviderEnum>;
};

export enum ProviderEnum {
  /** Custom */
  Custom = 'CUSTOM',
  /** GOG */
  Gog = 'GOG',
  /** Steam */
  Steam = 'STEAM',
  /** PlayStation */
  PlayStation = 'PLAY_STATION',
  /** Xbox */
  Xbox = 'XBOX'
}

export type GameMappingFragmentFragment = (
  { __typename?: 'GameMappingType' }
  & Pick<GameMappingType, 'gameId' | 'gameTitle' | 'igdbGameId' | 'igdbGameMappingIsVerified' | 'igdbGameMappingScore' | 'igdbGameTitle'>
);

export type Add_Custom_Games_MutationMutationVariables = Exact<{
  input: AddCustomGamesInputType;
}>;


export type Add_Custom_Games_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { addCustomGames?: Maybe<(
    { __typename?: 'AddCustomGamesResponseType' }
    & Pick<AddCustomGamesResponseType, 'succeeded' | 'gameIds'>
  )> }
);

export type Developer_Compute_Mapping_ScoresMutationVariables = Exact<{ [key: string]: never; }>;


export type Developer_Compute_Mapping_ScoresMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { developer: (
    { __typename?: 'DeveloperMutation' }
    & { computeMappingScores?: Maybe<Array<Maybe<(
      { __typename?: 'GameMappingType' }
      & GameMappingFragmentFragment
    )>>> }
  ) }
);

export type Developer_Refetch_Game_MappingMutationVariables = Exact<{
  input: RefetchGameMappingInputType;
}>;


export type Developer_Refetch_Game_MappingMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { developer: (
    { __typename?: 'DeveloperMutation' }
    & { refetchGameMapping?: Maybe<(
      { __typename?: 'GameMappingType' }
      & GameMappingFragmentFragment
    )> }
  ) }
);

export type Developer_Update_Game_MappingMutationVariables = Exact<{
  input: UpdateGameMappingInputType;
}>;


export type Developer_Update_Game_MappingMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { developer: (
    { __typename?: 'DeveloperMutation' }
    & { updateGameMapping?: Maybe<(
      { __typename?: 'GameMappingType' }
      & GameMappingFragmentFragment
    )> }
  ) }
);

export type Developer_Verify_Game_MappingMutationVariables = Exact<{
  input: VerifyGameMappingInputType;
}>;


export type Developer_Verify_Game_MappingMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { developer: (
    { __typename?: 'DeveloperMutation' }
    & { verifyGameMapping?: Maybe<(
      { __typename?: 'GameMappingType' }
      & GameMappingFragmentFragment
    )> }
  ) }
);

export type Developer_Update_Game_MappingsMutationVariables = Exact<{
  input: UpdateGameMappingsInputType;
}>;


export type Developer_Update_Game_MappingsMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { developer: (
    { __typename?: 'DeveloperMutation' }
    & { updateGameMappings?: Maybe<Array<Maybe<(
      { __typename?: 'GameMappingType' }
      & GameMappingFragmentFragment
    )>>> }
  ) }
);

export type Remove_Custom_Game_MutationMutationVariables = Exact<{
  input: RemoveCustomGameInputType;
}>;


export type Remove_Custom_Game_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { removeCustomGame?: Maybe<(
    { __typename?: 'RemoveCustomGameResponseType' }
    & Pick<RemoveCustomGameResponseType, 'succeeded'>
  )> }
);

export type Sync_Providers_MutationMutationVariables = Exact<{ [key: string]: never; }>;


export type Sync_Providers_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { syncProviders?: Maybe<(
    { __typename?: 'SyncProvidersResponseType' }
    & Pick<SyncProvidersResponseType, 'succeeded'>
  )> }
);

export type Update_Backlog_Position_MutationMutationVariables = Exact<{
  backlogPosition: Scalars['Int'];
  gameId: Scalars['String'];
}>;


export type Update_Backlog_Position_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateBacklogEntry?: Maybe<(
    { __typename?: 'BacklogEntryType' }
    & Pick<BacklogEntryType, 'backlogPosition'>
    & { game?: Maybe<(
      { __typename?: 'GameType' }
      & Pick<GameType, 'id'>
    )> }
  )> }
);

export type Update_Display_Name_MutationMutationVariables = Exact<{
  input: UpdateDisplayNameInputType;
}>;


export type Update_Display_Name_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateDisplayName?: Maybe<(
    { __typename?: 'UpdateDisplayNameResponseType' }
    & Pick<UpdateDisplayNameResponseType, 'displayName' | 'updateSucceeded'>
  )> }
);

export type Update_Following_MutationMutationVariables = Exact<{
  input: UpdateFollowingInputType;
}>;


export type Update_Following_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateFollowing?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isVisitorFollowing'>
  )> }
);

export type Update_Library_Entry_MutationMutationVariables = Exact<{
  gameId: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['Int']>;
  state?: Maybe<LibraryGameState>;
}>;


export type Update_Library_Entry_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateLibraryEntry?: Maybe<(
    { __typename?: 'LibraryEntryType' }
    & Pick<LibraryEntryType, 'isFavorite' | 'isHidden' | 'rating' | 'state'>
    & { game?: Maybe<(
      { __typename?: 'GameType' }
      & Pick<GameType, 'id'>
    )> }
  )> }
);

export type Update_Profile_Privacy_MutationMutationVariables = Exact<{
  input: UpdateProfilePrivacyInputType;
}>;


export type Update_Profile_Privacy_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateProfilePrivacy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isProfilePrivate'>
  )> }
);

export type Update_Provider_Profile_Name_MutationMutationVariables = Exact<{
  input: UpdateProviderProfileNameInputType;
}>;


export type Update_Provider_Profile_Name_MutationMutation = (
  { __typename?: 'AllMyGamesMutation' }
  & { updateProviderProfileName?: Maybe<(
    { __typename?: 'ProviderUpdateResponseType' }
    & Pick<ProviderUpdateResponseType, 'profileName' | 'providerState'>
  )> }
);

export type Backlog_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Backlog_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { backlog?: Maybe<Array<Maybe<(
      { __typename?: 'BacklogEntryType' }
      & Pick<BacklogEntryType, 'backlogPosition' | 'rating' | 'state' | 'provider'>
      & { game?: Maybe<(
        { __typename?: 'GameType' }
        & Pick<GameType, 'id' | 'title' | 'image'>
      )> }
    )>>> }
  )> }
);

export type Developer_Game_Mappings_QueryQueryVariables = Exact<{
  input: GameMappingsInputType;
}>;


export type Developer_Game_Mappings_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { developer: (
    { __typename?: 'DeveloperQuery' }
    & { gameMappings?: Maybe<(
      { __typename?: 'GameMappingsResponseType' }
      & Pick<GameMappingsResponseType, 'nextOffset'>
      & { results?: Maybe<Array<Maybe<(
        { __typename?: 'GameMappingType' }
        & GameMappingFragmentFragment
      )>>> }
    )> }
  ) }
);

export type Library_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Library_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { library?: Maybe<Array<Maybe<(
      { __typename?: 'LibraryEntryType' }
      & Pick<LibraryEntryType, 'isFavorite' | 'isHidden' | 'rating' | 'state' | 'provider'>
      & { game?: Maybe<(
        { __typename?: 'GameType' }
        & Pick<GameType, 'id' | 'title' | 'image'>
      )> }
    )>>> }
  )> }
);

export type Profile_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Profile_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'displayName' | 'gogProfileName' | 'isDeveloper' | 'isProfilePrivate' | 'psnProfileName' | 'steamProfileName' | 'xboxGamertag'>
  )> }
);

export type Search_Game_QueryQueryVariables = Exact<{
  input: SearchGameQueryInputType;
}>;


export type Search_Game_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { searchGame?: Maybe<(
    { __typename?: 'SearchGameQueryResponseType' }
    & { games?: Maybe<Array<Maybe<(
      { __typename?: 'GameSearchResultType' }
      & Pick<GameSearchResultType, 'igdbId' | 'image' | 'name'>
    )>>> }
  )> }
);

export type Social_Feed_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Social_Feed_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { feed?: Maybe<(
    { __typename?: 'SocialFeedType' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SocialFeedItemType' }
      & Pick<SocialFeedItemType, 'displayName' | 'eventAgeString' | 'text'>
    )>>> }
  )> }
);

export type User_Public_Profile_QueryQueryVariables = Exact<{
  input: UserQueryInputType;
}>;


export type User_Public_Profile_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'displayName' | 'isVisitorFollowing' | 'numFollowers' | 'numFollowing'>
    & { backlog?: Maybe<Array<Maybe<(
      { __typename?: 'BacklogEntryType' }
      & Pick<BacklogEntryType, 'backlogPosition'>
      & { game?: Maybe<(
        { __typename?: 'GameType' }
        & Pick<GameType, 'id' | 'title' | 'image'>
      )> }
    )>>>, collectionStats?: Maybe<(
      { __typename?: 'CollectionStatsType' }
      & Pick<CollectionStatsType, 'backlogCount' | 'completedCount' | 'libraryCount' | 'playingCount' | 'wishlistCount'>
    )>, favorites?: Maybe<Array<Maybe<(
      { __typename?: 'LibraryEntryType' }
      & { game?: Maybe<(
        { __typename?: 'GameType' }
        & Pick<GameType, 'id' | 'title' | 'image'>
      )> }
    )>>> }
  )> }
);

export type User_Followers_QueryQueryVariables = Exact<{
  input: UserQueryInputType;
}>;


export type User_Followers_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { followers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'isVisitorFollowing'>
    )>>> }
  )> }
);

export type User_Following_QueryQueryVariables = Exact<{
  input: UserQueryInputType;
}>;


export type User_Following_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { following?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'isVisitorFollowing'>
    )>>> }
  )> }
);

export type Wishlist_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type Wishlist_QueryQuery = (
  { __typename?: 'AllMyGamesQuery' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { wishlist?: Maybe<Array<Maybe<(
      { __typename?: 'WishlistEntryType' }
      & Pick<WishlistEntryType, 'provider'>
      & { game?: Maybe<(
        { __typename?: 'GameType' }
        & Pick<GameType, 'id' | 'image' | 'title'>
      )> }
    )>>> }
  )> }
);
